import React from 'react';
import ReactDOM from 'react-dom/client';
import store from './redux/store'
import { Provider, useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import App from './App';
import ScrollToTop  from '@/components/ScrollTop'
import "./style/_variables.less"
import './index.less'

function Index() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Provider store={store}>
          <App />
      </Provider>
    </BrowserRouter>
  )
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Index />
);