import { Form } from '@arco-design/web-react'
import './index.less'
import cs from 'classnames'

export function NewForm(props) {
  return <Form {...props} ref={props.r} className={cs('redqueenForm', props.className)}/>
}
export function NewFormItem(props) {
  return <Form.Item {...props} className={cs('redqueenFormItem', props.className)}/>;
}
