import React, {useRef} from 'react'
import { Select } from '@arco-design/web-react';
import cs from 'classnames'
import '../Tag/index.less'
import './index.less'

export default function NewSelect(props) {
    // const parentRef = useRef();
  return <Select {...props}  className={cs('redqueenSelect', props.className)}/>
}
