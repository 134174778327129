import { Radio } from '@arco-design/web-react'
import { nanoid } from 'nanoid'
import './index.less'
export default function NewRadio(props) {
    const RadioGroup = Radio.Group;
    return (
        <RadioGroup key={nanoid()} className={"redqueenRadio"} defaultValue={props.defaultValue} onChange={props.onChange} style={{...props.style}}>
            {
                props.data.map((item) => {
                    return <Radio defaultChecked={!!item.checked} key={item.value} disabled={props.disabled} value={item.value}>{item.label}</Radio>
                })
            }
        </RadioGroup>
    )
}
