import { Dropdown, Menu, Button } from "@arco-design/web-react"
import MyNavLink from '@/components/MyNavLink'
import { Link } from 'react-router-dom'
import { IconDown } from '@arco-design/web-react/icon';
import Bdown from '@/assets/message/bdown.svg'
import eventStation from '@/utils/eventStation'
import New from '@/assets/hole/Font.svg'
import cs from 'classnames'
import "./index.less"
// import cs from 'classnames'
// import './index.less'

export default function NewDropdown(props) {
    if (props.menu) {
        const dropList = () => {
            return (
                <Menu className="setMenuDropDownPosition">
                    {props.menu.map(item => (
                        // console.log(item.target)
                        <Menu.Item key={item.type}><Link to={item.to} target={item.target || ''} onClick={(event) => {
                            if (item.callback) {
                                item.callback()
                                event.preventDefault()
                            }
                            // item.callback ? item.callback() : ""
                            eventStation.messageCount()
                        }}>{item.children}</Link></Menu.Item>

                    ))}
                </Menu>
            )
        }
        return <Dropdown
            droplist={dropList()}
            trigger="hover"
            getPopupContainer={props.getPopupContainer}
            position="bl">
            <MyNavLink
                to={props.to}
            >
                <Button
                    onClick={(event) => {
                        event.preventDefault()
                    }}
                    className={cs("redqueenDropdown", 'menuListDropDown')}
                >{props.children}
                    <Bdown className='arco-icon-down'/>
                    {/* <IconDown/> */}
                </Button>
            </MyNavLink>
        </Dropdown>
    } else {
        return <MyNavLink to={props.to} style={{marginRight: props.type == 'loophole' ? 17 : 4}} onClick={() => {
            eventStation.messageCount()
        }}><Button className={cs("redqueenDropdown", 'menuListDropDown')}>{props.children}</Button>{props.type == 'loophole' ? <New className='topMenuNewIcon'/>: ""}</MyNavLink>
    }
}