/**
 * 主要验证数据格式
 * 由于文件代码量大，故单独封装在这个文件内，只需引入了public.js,此文件会自动引入，无需单独引入
 */
/**
 * 数据格式验证
 * @param {*} data 数据传入
 * @param {*} type 验证类型DOMAIN = 1;URL = 2;IP = 3;MD5=4;EMAIL=5;STRING = 8;SHA1 = 6;SHA256 = 7,ipv6=10, cve = 11, cnvd=12, cnnvd=13, 手机号=9,密码=14(5-20位,含字母数字特殊字符(空格除外)),是否有非法字符=15;
 */
export default function valid(data, type) {
    var domainPattern = /^(?=^.{3,255}$)(xn--)?(www\.)?[a-zA-Z0-9][\_\-a-zA-Z0-9]{0,62}(\.(xn--)?[a-zA-Z0-9][\_\-a-zA-Z0-9]{0,62})+$/,
        ftpPattern = /^ftp:\/\/[\s\S]+/,
        urlPattern = /^(http|ftp|https|ldap|ws|tcp|tps|udp|wss):\/\/[@:\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&amp;:/~\+#\(\[\]]*[\w\-\@?^=%&amp;/~\+#\[\]])$/,
        ipPattern = /^((?:(?:25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))\.){3}(?:25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d))))$/,
        ipv6Pattern = /^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))$/,
        md5Pattern = /^[a-fA-F0-9]{16}$|^[a-fA-F0-9]{32}$/,
        emailPattern = /^[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?$/,
        SHA1Pattern = /^[A-Fa-f0-9]{40}$/,
        SHA256Pattern = /^[A-Fa-f0-9]{64}$/, 
        cveIdPattern = /^(CVE-\d{4}-\d{4,19})|(CVE-(2\d{3}|1999)-\d{4,})$/i,
        cnvdIdPattern = /^(CNVD-(2\d{3}|19\d\d)-\d{4,19})|(CNVD-\d{4}-\d{1,5})$/i,
        cnnvdIdPattern = /^CNNVD-(2\d{3}(0[1-9]|1[0-2])|19\d{2}(0[1-9]|1[0-2]))-\d{3,}$/i,
        phonePattern = /^1[3|4|5|6|7|8|9|][0-9]{9}$/,
        // passwordPattern = /(?!^[0-9]+$)(?!^[A-z]+$)(?!^[`~!@#\$%\^\&\*\(\)_\+\-=\[\]\\\\;',\./\{\}\|:\<\>\?\"]+$)^[^\\s]{5,20}$/,
        passwordPattern = /(?!^[0-9]+$)(?!^[A-z]+$)(?!^[`~!@#\$%\^\&\*\(\)_\+\-=\[\]\\;',\./\{\}\|:\<\>\?"]+$)^[^\s]/,
                           
        characterPattern = /[\%,\},\{,\;,\+,\",\(,\),\<,\>]/,
        result;
    if (domainPattern.test(data) && hasDomainSuffix(data)) {
        result = 1;
    } else if (urlPattern.test(data) || ftpPattern.test(data)) {
        result = 2;
    } else if (ipPattern.test(data)) {
        result = 3;
    } else if (md5Pattern.test(data)) {
        result = 4
    } else if (emailPattern.test(data)) {
        result = 5
    } else if (SHA1Pattern.test(data)) {
        result = 6
    } else if (SHA256Pattern.test(data)) {
        result = 7
    } else if (phonePattern.test(data)) {
        result = 9
    } else if (ipv6Pattern.test(data)) {
        result = 10
    } else if (cveIdPattern.test(data)) {
        result = 11
    } else if (cnvdIdPattern.test(data)) {
        result = 12
    } else if (cnnvdIdPattern.test(data)) {
        result = 13
    } else if (passwordPattern.test(data)) {
        result = 14
    } else if (characterPattern.test(data)) {
        result = 15
    } else {
        result = 8
    }
    if (type) {
        return type == result;
    } else {
        return result;
    }
}
function hasDomainSuffix(query) {
    if (query === undefined || query === null || query.trim() === "" || query.charAt(query.length - 1) === ".") {
        return false;
    }
    var temps = query.split(".");
    if (null === temps || temps.length < 2) {
        return false;
    }
    if (suffixArray.indexOf(temps[temps.length - 1].split(":")[0].toLowerCase()) != -1) {
        return true;
    }
    return false;
};
let suffixArray = [];
suffixarrayInit();
function suffixarrayInit() {
    suffixArray.push("gq");
	suffixArray.push("uz");
	suffixArray.push("onion");
	suffixArray.push("im");
	suffixArray.push("gg");
	suffixArray.push("mu");
	suffixArray.push("xn--p1ai");
	suffixArray.push("mk");
	suffixArray.push("gdn");
	suffixArray.push("ps");
	suffixArray.push("rs");
	suffixArray.push("bit");
	suffixArray.push("aero");
	suffixArray.push("asia");
	suffixArray.push("biz");
	suffixArray.push("cc");
	suffixArray.push("cd");
	suffixArray.push("club");
	suffixArray.push("cn");
	suffixArray.push("co");
	suffixArray.push("com");
	suffixArray.push("coop");
	suffixArray.push("edu");
	suffixArray.push("gov");
	suffixArray.push("idv");
	suffixArray.push("info");
	suffixArray.push("int");
	suffixArray.push("mil");
	suffixArray.push("mobi");
	suffixArray.push("museum");
	suffixArray.push("name");
	suffixArray.push("net");
	suffixArray.push("org");
	suffixArray.push("pro");
	suffixArray.push("pub");
	suffixArray.push("red");
	suffixArray.push("ren");
	suffixArray.push("site");
	suffixArray.push("tel");
	suffixArray.push("top");
	suffixArray.push("travel");
	suffixArray.push("tv");
	suffixArray.push("wang");
	suffixArray.push("win");
	suffixArray.push("xin");
	suffixArray.push("xxx");
	suffixArray.push("xyz");

	suffixArray.push("ad");
	suffixArray.push("ae");
	suffixArray.push("af");
	suffixArray.push("ag");
	suffixArray.push("ai");
	suffixArray.push("al");
	suffixArray.push("am");
	suffixArray.push("an");
	suffixArray.push("ao");
	suffixArray.push("aq");
	suffixArray.push("ar");
	suffixArray.push("as");
	suffixArray.push("at");
	suffixArray.push("au");
	suffixArray.push("aw");
	suffixArray.push("az");
	suffixArray.push("ba");
	suffixArray.push("bb");
	suffixArray.push("bd");
	suffixArray.push("be");
	suffixArray.push("bf");
	suffixArray.push("bg");
	suffixArray.push("bh");
	suffixArray.push("bi");
	suffixArray.push("bj");
	suffixArray.push("bm");
	suffixArray.push("bn");
	suffixArray.push("bo");
	suffixArray.push("br");
	suffixArray.push("bs");
	suffixArray.push("bt");
	suffixArray.push("bv");
	suffixArray.push("bw");
	suffixArray.push("by");
	suffixArray.push("bz");
	suffixArray.push("ca");
	suffixArray.push("cc");
	suffixArray.push("cf");
	suffixArray.push("cg");
	suffixArray.push("ch");
	suffixArray.push("ci");
	suffixArray.push("ck");
	suffixArray.push("cl");
	suffixArray.push("cm");
	suffixArray.push("co");
	suffixArray.push("cq");
	suffixArray.push("cr");
	suffixArray.push("cu");
	suffixArray.push("cv");
	suffixArray.push("cx");
	suffixArray.push("cy");
	suffixArray.push("cz");
	suffixArray.push("de");
	suffixArray.push("dj");
	suffixArray.push("dk");
	suffixArray.push("dm");
	suffixArray.push("do");
	suffixArray.push("dz");
	suffixArray.push("ec");
	suffixArray.push("ee");
	suffixArray.push("eg");
	suffixArray.push("eh");
	suffixArray.push("es");
	suffixArray.push("et");
	suffixArray.push("eu");
	suffixArray.push("ev");
	suffixArray.push("fi");
	suffixArray.push("fj");
	suffixArray.push("fk");
	suffixArray.push("fm");
	suffixArray.push("fo");
	suffixArray.push("fr");
	suffixArray.push("ga");
	suffixArray.push("gb");
	suffixArray.push("gd");
	suffixArray.push("ge");
	suffixArray.push("gf");
	suffixArray.push("gh");
	suffixArray.push("gi");
	suffixArray.push("gl");
	suffixArray.push("gm");
	suffixArray.push("gn");
	suffixArray.push("gp");
	suffixArray.push("gr");
	suffixArray.push("gs");
	suffixArray.push("gt");
	suffixArray.push("gu");
	suffixArray.push("gw");
	suffixArray.push("gy");
	suffixArray.push("hk");
	suffixArray.push("hm");
	suffixArray.push("hn");
	suffixArray.push("hr");
	suffixArray.push("ht");
	suffixArray.push("hu");
	suffixArray.push("id");
	suffixArray.push("ie");
	suffixArray.push("il");
	suffixArray.push("in");
	suffixArray.push("io");
	suffixArray.push("iq");
	suffixArray.push("ir");
	suffixArray.push("is");
	suffixArray.push("it");
	suffixArray.push("jm");
	suffixArray.push("jo");
	suffixArray.push("jp");
	suffixArray.push("ke");
	suffixArray.push("kg");
	suffixArray.push("kh");
	suffixArray.push("ki");
	suffixArray.push("km");
	suffixArray.push("kn");
	suffixArray.push("kp");
	suffixArray.push("kr");
	suffixArray.push("kw");
	suffixArray.push("ky");
	suffixArray.push("kz");
	suffixArray.push("la");
	suffixArray.push("lb");
	suffixArray.push("lc");
	suffixArray.push("li");
	suffixArray.push("lk");
	suffixArray.push("lr");
	suffixArray.push("ls");
	suffixArray.push("lt");
	suffixArray.push("lu");
	suffixArray.push("lv");
	suffixArray.push("ly");
	suffixArray.push("ma");
	suffixArray.push("mc");
	suffixArray.push("me");
	suffixArray.push("md");
	suffixArray.push("mg");
	suffixArray.push("mh");
	suffixArray.push("ml");
	suffixArray.push("mm");
	suffixArray.push("mn");
	suffixArray.push("mo");
	suffixArray.push("mp");
	suffixArray.push("mq");
	suffixArray.push("mr");
	suffixArray.push("ms");
	suffixArray.push("mt");
	suffixArray.push("mv");
	suffixArray.push("mw");
	suffixArray.push("mx");
	suffixArray.push("my");
	suffixArray.push("mz");
	suffixArray.push("na");
	suffixArray.push("nc");
	suffixArray.push("ne");
	suffixArray.push("nf");
	suffixArray.push("ng");
	suffixArray.push("ni");
	suffixArray.push("nl");
	suffixArray.push("no");
	suffixArray.push("np");
	suffixArray.push("nr");
	suffixArray.push("nt");
	suffixArray.push("nu");
	suffixArray.push("nz");
	suffixArray.push("om");
	suffixArray.push("pa");
	suffixArray.push("pe");
	suffixArray.push("pf");
	suffixArray.push("pg");
	suffixArray.push("ph");
	suffixArray.push("pk");
	suffixArray.push("pl");
	suffixArray.push("pm");
	suffixArray.push("pn");
	suffixArray.push("pr");
	suffixArray.push("pt");
	suffixArray.push("pw");
	suffixArray.push("py");
	suffixArray.push("qa");
	suffixArray.push("re");
	suffixArray.push("ro");
	suffixArray.push("ru");
	suffixArray.push("rw");
	suffixArray.push("sa");
	suffixArray.push("sb");
	suffixArray.push("sc");
	suffixArray.push("sd");
	suffixArray.push("se");
	suffixArray.push("sg");
	suffixArray.push("sh");
	suffixArray.push("si");
	suffixArray.push("sj");
	suffixArray.push("sk");
	suffixArray.push("sl");
	suffixArray.push("sm");
	suffixArray.push("sn");
	suffixArray.push("so");
	suffixArray.push("sr");
	suffixArray.push("st");
	suffixArray.push("su");
	suffixArray.push("sy");
	suffixArray.push("sz");
	suffixArray.push("tc");
	suffixArray.push("td");
	suffixArray.push("tf");
	suffixArray.push("tg");
	suffixArray.push("th");
	suffixArray.push("tj");
	suffixArray.push("tk");
	suffixArray.push("tl");
	suffixArray.push("tm");
	suffixArray.push("tn");
	suffixArray.push("to");
	suffixArray.push("tp");
	suffixArray.push("tr");
	suffixArray.push("tt");
	suffixArray.push("tw");
	suffixArray.push("tz");
	suffixArray.push("ua");
	suffixArray.push("ug");
	suffixArray.push("uk");
	suffixArray.push("us");
	suffixArray.push("uy");
	suffixArray.push("va");
	suffixArray.push("vc");
	suffixArray.push("ve");
	suffixArray.push("vg");
	suffixArray.push("vn");
	suffixArray.push("vu");
	suffixArray.push("wf");
	suffixArray.push("ws");
	suffixArray.push("ye");
	suffixArray.push("yu");
	suffixArray.push("za");
	suffixArray.push("zm");
	suffixArray.push("zr");
	suffixArray.push("zw");

	suffixArray.push("com");
	suffixArray.push("net");
	suffixArray.push("org");
	suffixArray.push("xyz");
	suffixArray.push("info");
	suffixArray.push("top");
	suffixArray.push("biz");
	suffixArray.push("win");
	suffixArray.push("wang");
	suffixArray.push("club");
	suffixArray.push("mobi");
	suffixArray.push("site");
	suffixArray.push("bid");
	suffixArray.push("online");
	suffixArray.push("vip");
	suffixArray.push("pro");
	suffixArray.push("ren");
	suffixArray.push("red");
	suffixArray.push("link");
	suffixArray.push("loan");
	suffixArray.push("tech");
	suffixArray.push("science");
	suffixArray.push("space");
	suffixArray.push("website");
	suffixArray.push("trade");
	suffixArray.push("racing");
	suffixArray.push("date");
	suffixArray.push("xin");
	suffixArray.push("click");
	suffixArray.push("kim");
	suffixArray.push("cat");
	suffixArray.push("xxx");
	suffixArray.push("shop");
	suffixArray.push("download");
	suffixArray.push("cloud");
	suffixArray.push("work");
	suffixArray.push("store");
	suffixArray.push("news");
	suffixArray.push("lol");
	suffixArray.push("nyc");
	suffixArray.push("live");
	suffixArray.push("rocks");
	suffixArray.push("party");
	suffixArray.push("realtor");
	suffixArray.push("pub");
	suffixArray.push("review");
	suffixArray.push("one");
	suffixArray.push("email");
	suffixArray.push("guru");
	suffixArray.push("berlin");
	suffixArray.push("today");
	suffixArray.push("london");
	suffixArray.push("solutions");
	suffixArray.push("accountant");
	suffixArray.push("ovh");
	suffixArray.push("mom");
	suffixArray.push("design");
	suffixArray.push("photography");
	suffixArray.push("tokyo");
	suffixArray.push("company");
	suffixArray.push("jobs");
	suffixArray.push("press");
	suffixArray.push("life");
	suffixArray.push("men");
	suffixArray.push("blue");
	suffixArray.push("faith");
	suffixArray.push("ltd");
	suffixArray.push("pink");
	suffixArray.push("webcam");
	suffixArray.push("help");
	suffixArray.push("host");
	suffixArray.push("ninja");
	suffixArray.push("tips");
	suffixArray.push("world");
	suffixArray.push("city");
	suffixArray.push("center");
	suffixArray.push("bayern");
	suffixArray.push("stream");
	suffixArray.push("media");
	suffixArray.push("expert");
	suffixArray.push("agency");
	suffixArray.push("bet");
	suffixArray.push("global");
	suffixArray.push("technology");
	suffixArray.push("amsterdam");
	suffixArray.push("photo");
	suffixArray.push("ink");
	suffixArray.push("hamburg");
	suffixArray.push("koeln");
	suffixArray.push("black");
	suffixArray.push("services");
	suffixArray.push("gift");
	suffixArray.push("cricket");
	suffixArray.push("academy");
	suffixArray.push("paris");
	suffixArray.push("systems");
	suffixArray.push("wiki");
	suffixArray.push("international");
	suffixArray.push("education");
	suffixArray.push("studio");
	suffixArray.push("photos");
	suffixArray.push("directory");
	suffixArray.push("consulting");
	suffixArray.push("events");
	suffixArray.push("casa");
	suffixArray.push("gallery");
	suffixArray.push("digital");
	suffixArray.push("network");
	suffixArray.push("video");
	suffixArray.push("travel");
	suffixArray.push("support");
	suffixArray.push("social");
	suffixArray.push("uno");
	suffixArray.push("training");
	suffixArray.push("run");
	suffixArray.push("istanbul");
	suffixArray.push("swiss");
	suffixArray.push("sexy");
	suffixArray.push("zone");
	suffixArray.push("love");
	suffixArray.push("reviews");
	suffixArray.push("marketing");
	suffixArray.push("church");
	suffixArray.push("wien");
	suffixArray.push("house");
	suffixArray.push("bio");
	suffixArray.push("frl");
	suffixArray.push("bike");
	suffixArray.push("land");
	suffixArray.push("pics");
	suffixArray.push("clothing");
	suffixArray.push("business");
	suffixArray.push("guide");
	suffixArray.push("cool");
	suffixArray.push("gmbh");
	suffixArray.push("ooo");
	suffixArray.push("care");
	suffixArray.push("ist");
	suffixArray.push("ruhr");
	suffixArray.push("property");
	suffixArray.push("lawyer");
	suffixArray.push("immo");
	suffixArray.push("miami");
	suffixArray.push("sex");
	suffixArray.push("porn");
	suffixArray.push("sale");
	suffixArray.push("wales");
	suffixArray.push("vegas");
	suffixArray.push("coffee");
	suffixArray.push("wine");
	suffixArray.push("family");
	suffixArray.push("scot");
	suffixArray.push("market");
	suffixArray.push("works");
	suffixArray.push("software");
	suffixArray.push("nrw");
	suffixArray.push("buzz");
	suffixArray.push("properties");
	suffixArray.push("beer");
	suffixArray.push("group");
	suffixArray.push("kiwi");
	suffixArray.push("rentals");
	suffixArray.push("community");
	suffixArray.push("pet");
	suffixArray.push("farm");
	suffixArray.push("management");
	suffixArray.push("melbourne");
	suffixArray.push("adult");
	suffixArray.push("direct");
	suffixArray.push("estate");
	suffixArray.push("quebec");
	suffixArray.push("legal");
	suffixArray.push("sydney");
	suffixArray.push("gold");
	suffixArray.push("band");
	suffixArray.push("team");
	suffixArray.push("kaufen");
	suffixArray.push("chat");
	suffixArray.push("school");
	suffixArray.push("barcelona");
	suffixArray.push("fit");
	suffixArray.push("fund");
	suffixArray.push("fashion");
	suffixArray.push("institute");
	suffixArray.push("fitness");
	suffixArray.push("boutique");
	suffixArray.push("attorney");
	suffixArray.push("cafe");
	suffixArray.push("rent");
	suffixArray.push("audio");
	suffixArray.push("coop");
	suffixArray.push("deals");
	suffixArray.push("healthcare");
	suffixArray.push("ventures");
	suffixArray.push("wtf");
	suffixArray.push("games");
	suffixArray.push("tools");
	suffixArray.push("coach");
	suffixArray.push("graphics");
	suffixArray.push("foundation");
	suffixArray.push("immobilien");
	suffixArray.push("sucks");
	suffixArray.push("wedding");
	suffixArray.push("fyi");
	suffixArray.push("pictures");
	suffixArray.push("careers");
	suffixArray.push("yoga");
	suffixArray.push("brussels");
	suffixArray.push("dental");
	suffixArray.push("domains");
	suffixArray.push("dog");
	suffixArray.push("solar");
	suffixArray.push("vlaanderen");
	suffixArray.push("jetzt");
	suffixArray.push("cymru");
	suffixArray.push("construction");
	suffixArray.push("equipment");
	suffixArray.push("bzh");
	suffixArray.push("plus");
	suffixArray.push("menu");
	suffixArray.push("vin");
	suffixArray.push("forsale");
	suffixArray.push("money");
	suffixArray.push("lighting");
	suffixArray.push("repair");
	suffixArray.push("restaurant");
	suffixArray.push("eus");
	suffixArray.push("kitchen");
	suffixArray.push("camp");
	suffixArray.push("law");
	suffixArray.push("style");
	suffixArray.push("clinic");
	suffixArray.push("cash");
	suffixArray.push("golf");
	suffixArray.push("vet");
	suffixArray.push("moe");
	suffixArray.push("ski");
	suffixArray.push("shoes");
	suffixArray.push("capital");
	suffixArray.push("hosting");
	suffixArray.push("cards");
	suffixArray.push("college");
	suffixArray.push("tours");
	suffixArray.push("earth");
	suffixArray.push("vision");
	suffixArray.push("watch");
	suffixArray.push("cologne");
	suffixArray.push("parts");
	suffixArray.push("enterprises");
	suffixArray.push("productions");
	suffixArray.push("codes");
	suffixArray.push("energy");
	suffixArray.push("dance");
	suffixArray.push("camera");
	suffixArray.push("holiday");
	suffixArray.push("report");
	suffixArray.push("bar");
	suffixArray.push("partners");
	suffixArray.push("finance");
	suffixArray.push("holdings");
	suffixArray.push("reisen");
	suffixArray.push("university");
	suffixArray.push("tirol");
	suffixArray.push("computer");
	suffixArray.push("exchange");
	suffixArray.push("tax");
	suffixArray.push("limited");
	suffixArray.push("capetown");
	suffixArray.push("taxi");
	suffixArray.push("onl");
	suffixArray.push("singles");
	suffixArray.push("show");
	suffixArray.push("haus");
	suffixArray.push("discount");
	suffixArray.push("football");
	suffixArray.push("contractors");
	suffixArray.push("shopping");
	suffixArray.push("pizza");
	suffixArray.push("poker");
	suffixArray.push("vacations");
	suffixArray.push("nagoya");
	suffixArray.push("express");
	suffixArray.push("toys");
	suffixArray.push("engineering");
	suffixArray.push("place");
	suffixArray.push("insure");
	suffixArray.push("build");
	suffixArray.push("cab");
	suffixArray.push("builders");
	suffixArray.push("gratis");
	suffixArray.push("green");
	suffixArray.push("plumbing");
	suffixArray.push("supply");
	suffixArray.push("saarland");
	suffixArray.push("fish");
	suffixArray.push("diet");
	suffixArray.push("ngo");
	suffixArray.push("ong");
	suffixArray.push("gifts");
	suffixArray.push("degree");
	suffixArray.push("joburg");
	suffixArray.push("glass");
	suffixArray.push("gal");
	suffixArray.push("promo");
	suffixArray.push("financial");
	suffixArray.push("gent");
	suffixArray.push("casino");
	suffixArray.push("yokohama");
	suffixArray.push("catering");
	suffixArray.push("archi");
	suffixArray.push("apartments");
	suffixArray.push("recipes");
	suffixArray.push("auction");
	suffixArray.push("srl");
	suffixArray.push("cheap");
	suffixArray.push("feedback");
	suffixArray.push("futbol");
	suffixArray.push("industries");
	suffixArray.push("dentist");
	suffixArray.push("bank");
	suffixArray.push("supplies");
	suffixArray.push("loans");
	suffixArray.push("okinawa");
	suffixArray.push("delivery");
	suffixArray.push("best");
	suffixArray.push("diamonds");
	suffixArray.push("mortgage");
	suffixArray.push("lgbt");
	suffixArray.push("investments");
	suffixArray.push("voyage");
	suffixArray.push("town");
	suffixArray.push("dating");
	suffixArray.push("tienda");
	suffixArray.push("rip");
	suffixArray.push("surf");
	suffixArray.push("limo");
	suffixArray.push("florist");
	suffixArray.push("horse");
	suffixArray.push("ceo");
	suffixArray.push("taipei");
	suffixArray.push("fail");
	suffixArray.push("tattoo");
	suffixArray.push("moda");
	suffixArray.push("film");
	suffixArray.push("cleaning");
	suffixArray.push("christmas");
	suffixArray.push("mba");
	suffixArray.push("durban");
	suffixArray.push("engineer");
	suffixArray.push("exposed");
	suffixArray.push("associates");
	suffixArray.push("jewelry");
	suffixArray.push("how");
	suffixArray.push("bargains");
	suffixArray.push("lat");
	suffixArray.push("credit");
	suffixArray.push("game");
	suffixArray.push("soccer");
	suffixArray.push("versicherung");
	suffixArray.push("condos");
	suffixArray.push("furniture");
	suffixArray.push("schule");
	suffixArray.push("desi");
	suffixArray.push("cruises");
	suffixArray.push("gop");
	suffixArray.push("irish");
	suffixArray.push("cooking");
	suffixArray.push("alsace");
	suffixArray.push("flights");
	suffixArray.push("blackfriday");
	suffixArray.push("garden");
	suffixArray.push("doctor");
	suffixArray.push("study");
	suffixArray.push("flowers");
	suffixArray.push("surgery");
	suffixArray.push("vote");
	suffixArray.push("vodka");
	suffixArray.push("villas");
	suffixArray.push("fishing");
	suffixArray.push("rehab");
	suffixArray.push("blog");
	suffixArray.push("mma");
	suffixArray.push("hiphop");
	suffixArray.push("soy");
	suffixArray.push("actor");
	suffixArray.push("rest");
	suffixArray.push("lease");
	suffixArray.push("guitars");
	suffixArray.push("courses");
	suffixArray.push("coupons");
	suffixArray.push("fans");
	suffixArray.push("tube");
	suffixArray.push("claims");
	suffixArray.push("country");
	suffixArray.push("viajes");
	suffixArray.push("tennis");
	suffixArray.push("rio");
	suffixArray.push("army");
	suffixArray.push("reise");
	suffixArray.push("accountants");
	suffixArray.push("museum");
	suffixArray.push("physio");
	suffixArray.push("career");
	suffixArray.push("movie");
	suffixArray.push("gives");
	suffixArray.push("maison");
	suffixArray.push("juegos");
	suffixArray.push("hockey");
	suffixArray.push("tickets");
	suffixArray.push("bingo");
	suffixArray.push("gripe");
	suffixArray.push("luxury");
	suffixArray.push("salon");
	suffixArray.push("tatar");
	suffixArray.push("sarl");
	suffixArray.push("theater");
	suffixArray.push("corsica");
	suffixArray.push("republican");
	suffixArray.push("democrat");
	suffixArray.push("rodeo");
	suffixArray.push("creditcard");
	suffixArray.push("tires");
	suffixArray.push("shiksha");
	suffixArray.push("navy");
	suffixArray.push("osaka");
	suffixArray.push("kyoto");
	suffixArray.push("neustar");
	suffixArray.push("ryukyu");
	suffixArray.push("bible");
	suffixArray.push("qpon");
	suffixArray.push("memorial");
	suffixArray.push("organic");
	suffixArray.push("auto");
	suffixArray.push("markets");
	suffixArray.push("car");
	suffixArray.push("ltda");
	suffixArray.push("airforce");
	suffixArray.push("cars");
	suffixArray.push("hiv");
	suffixArray.push("krd");
	suffixArray.push("trading");
	suffixArray.push("storage");
	suffixArray.push("voto");
	suffixArray.push("security");
	suffixArray.push("hoteles");
	suffixArray.push("abogado");
	suffixArray.push("homes");
	suffixArray.push("broker");
	suffixArray.push("insurance");
	suffixArray.push("boats");
	suffixArray.push("reit");
	suffixArray.push("pharmacy");
	suffixArray.push("theatre");
	suffixArray.push("nra");
	suffixArray.push("citic");
	suffixArray.push("yachts");
	suffixArray.push("bradesco");
	suffixArray.push("makeup");
	suffixArray.push("weir");
	suffixArray.push("wed");
	suffixArray.push("protection");
	suffixArray.push("seat");
	suffixArray.push("barclays");
	suffixArray.push("abbott");
	suffixArray.push("leclerc");
	suffixArray.push("cam");
	suffixArray.push("moi");
	suffixArray.push("lotto");
	suffixArray.push("cancerresearch");
	suffixArray.push("rich");
	suffixArray.push("forex");
	suffixArray.push("whoswho");
	suffixArray.push("bloomberg");
	suffixArray.push("fage");
	suffixArray.push("motorcycles");
	suffixArray.push("linde");
	suffixArray.push("globo");
	suffixArray.push("iselect");
	suffixArray.push("audi");
	suffixArray.push("trust");
	suffixArray.push("monash");
	suffixArray.push("autos");
	suffixArray.push("man");
	suffixArray.push("microsoft");
	suffixArray.push("uol");
	suffixArray.push("firmdale");
	suffixArray.push("sener");
	suffixArray.push("sky");
	suffixArray.push("pictet");
	suffixArray.push("abb");
	suffixArray.push("mango");
	suffixArray.push("windows");
	suffixArray.push("xbox");
	suffixArray.push("mini");
	suffixArray.push("med");
	suffixArray.push("shriram");
	suffixArray.push("bing");
	suffixArray.push("aco");
	suffixArray.push("clubmed");
	suffixArray.push("foo");
	suffixArray.push("orange");
	suffixArray.push("cba");
	suffixArray.push("arte");
	suffixArray.push("bentley");
	suffixArray.push("bbva");
	suffixArray.push("saxo");
	suffixArray.push("hotmail");
	suffixArray.push("aig");
	suffixArray.push("azure");
	suffixArray.push("tatamotors");
	suffixArray.push("dvag");
	suffixArray.push("cisco");
	suffixArray.push("maif");
	suffixArray.push("chanel");
	suffixArray.push("kpn");
	suffixArray.push("cern");
	suffixArray.push("java");
	suffixArray.push("oracle");
	suffixArray.push("axa");
	suffixArray.push("accenture");
	suffixArray.push("lupin");
	suffixArray.push("sanofi");
	suffixArray.push("xperia");
	suffixArray.push("aquarelle");
	suffixArray.push("next");
	suffixArray.push("blanco");
	suffixArray.push("art");
	suffixArray.push("bms");
	suffixArray.push("bmw");
	suffixArray.push("philips");
	suffixArray.push("aeg");
	suffixArray.push("liaison");
	suffixArray.push("statoil");
	suffixArray.push("ubs");
	suffixArray.push("genting");
	suffixArray.push("jaguar");
	suffixArray.push("landrover");
	suffixArray.push("rwe");
	suffixArray.push("sbs");
	suffixArray.push("bosch");
	suffixArray.push("rexroth");
	suffixArray.push("weber");
	suffixArray.push("nikon");
	suffixArray.push("kerryhotels");
	suffixArray.push("nissay");
	suffixArray.push("lipsy");
	suffixArray.push("nextdirect");
	suffixArray.push("warman");
	suffixArray.push("airbus");
	suffixArray.push("ericsson");
	suffixArray.push("lego");
	suffixArray.push("shangrila");
	suffixArray.push("lundbeck");
	suffixArray.push("ladbrokes");
	suffixArray.push("barclaycard");
	suffixArray.push("yandex");
	suffixArray.push("schwarz");
	suffixArray.push("google");
	suffixArray.push("toray");
	suffixArray.push("csc");
	suffixArray.push("praxi");
	suffixArray.push("jprs");
	suffixArray.push("scb");
	suffixArray.push("williamhill");
	suffixArray.push("afl");
	suffixArray.push("realty");
	suffixArray.push("fox");
	suffixArray.push("goog");
	suffixArray.push("otsuka");
	suffixArray.push("deloitte");
	suffixArray.push("forum");
	suffixArray.push("lamborghini");
	suffixArray.push("jcb");
	suffixArray.push("sap");
	suffixArray.push("lidl");
	suffixArray.push("shell");
	suffixArray.push("total");
	suffixArray.push("walter");
	suffixArray.push("sandvik");
	suffixArray.push("emerck");
	suffixArray.push("dabur");
	suffixArray.push("redstone");
	suffixArray.push("gmo");
	suffixArray.push("skin");
	suffixArray.push("active");
	suffixArray.push("nico");
	suffixArray.push("samsung");
	suffixArray.push("spreadbetting");
	suffixArray.push("komatsu");
	suffixArray.push("lancaster");
	suffixArray.push("stc");
	suffixArray.push("dnp");
	suffixArray.push("sew");
	suffixArray.push("hitachi");
	suffixArray.push("sca");
	suffixArray.push("latrobe");
	suffixArray.push("ntt");
	suffixArray.push("cfd");
	suffixArray.push("sony");
	suffixArray.push("erni");
	suffixArray.push("pnc");
	suffixArray.push("swatch");
	suffixArray.push("fan");
	suffixArray.push("brother");
	suffixArray.push("skype");
	suffixArray.push("mtn");
	suffixArray.push("seven");
	suffixArray.push("norton");
	suffixArray.push("sfr");
	suffixArray.push("frogans");
	suffixArray.push("suzuki");
	suffixArray.push("crs");
	suffixArray.push("guardian");
	suffixArray.push("sandvikcoromant");
	suffixArray.push("sohu");
	suffixArray.push("edeka");
	suffixArray.push("abc");
	suffixArray.push("afamilycompany");
	suffixArray.push("ice");
	suffixArray.push("aws");
	suffixArray.push("wme");
	suffixArray.push("everbank");
	suffixArray.push("bridgestone");
	suffixArray.push("cfa");
	suffixArray.push("jll");
	suffixArray.push("ricoh");
	suffixArray.push("vistaprint");
	suffixArray.push("toyota");
	suffixArray.push("ferrero");
	suffixArray.push("fairwinds");
	suffixArray.push("dubai");
	suffixArray.push("extraspace");
	suffixArray.push("dhl");
	suffixArray.push("marriott");
	suffixArray.push("eurovision");
	suffixArray.push("volkswagen");
	suffixArray.push("dell");
	suffixArray.push("schmidt");
	suffixArray.push("canon");
	suffixArray.push("nec");
	suffixArray.push("nadex");
	suffixArray.push("page");
	suffixArray.push("sncf");
	suffixArray.push("omega");
	suffixArray.push("ipiranga");
	suffixArray.push("amica");
	suffixArray.push("kinder");
	suffixArray.push("gucci");
	suffixArray.push("aarp");
	suffixArray.push("symantec");
	suffixArray.push("travelers");
	suffixArray.push("jmp");
	suffixArray.push("tiffany");
	suffixArray.push("teva");
	suffixArray.push("itv");
	suffixArray.push("bugatti");
	suffixArray.push("mtr");
	suffixArray.push("nokia");
	suffixArray.push("glade");
	suffixArray.push("here");
	suffixArray.push("prod");
	suffixArray.push("app");
	suffixArray.push("trv");
	suffixArray.push("youtube");
	suffixArray.push("hermes");
	suffixArray.push("gmail");
	suffixArray.push("ads");
	suffixArray.push("new");
	suffixArray.push("toshiba");
	suffixArray.push("goo");
	suffixArray.push("netbank");
	suffixArray.push("vista");
	suffixArray.push("crown");
	suffixArray.push("lexus");
	suffixArray.push("aaa");
	suffixArray.push("aramco");
	suffixArray.push("ping");
	suffixArray.push("rocher");
	suffixArray.push("grainger");
	suffixArray.push("redumbrella");
	suffixArray.push("travelersinsurance");
	suffixArray.push("statefarm");
	suffixArray.push("lanxess");
	suffixArray.push("natura");
	suffixArray.push("quest");
	suffixArray.push("chase");
	suffixArray.push("jpmorgan");
	suffixArray.push("passagens");
	suffixArray.push("vuelos");
	suffixArray.push("locus");
	suffixArray.push("jcp");
	suffixArray.push("bbc");
	suffixArray.push("sbi");
	suffixArray.push("schaeffler");
	suffixArray.push("broadway");
	suffixArray.push("olayan");
	suffixArray.push("olayangroup");
	suffixArray.push("dupont");
	suffixArray.push("nfl");
	suffixArray.push("allfinanz");
	suffixArray.push("duck");
	suffixArray.push("scjohnson");
	suffixArray.push("lilly");
	suffixArray.push("spiegel");
	suffixArray.push("cuisinella");
	suffixArray.push("boo");
	suffixArray.push("channel");
	suffixArray.push("dad");
	suffixArray.push("day");
	suffixArray.push("eat");
	suffixArray.push("esq");
	suffixArray.push("fly");
	suffixArray.push("gbiz");
	suffixArray.push("ing");
	suffixArray.push("meme");
	suffixArray.push("mov");
	suffixArray.push("prof");
	suffixArray.push("rsvp");
	suffixArray.push("zip");
	suffixArray.push("xerox");
	suffixArray.push("dev");
	suffixArray.push("office");
	suffixArray.push("yahoo");
	suffixArray.push("telefonica");
	suffixArray.push("baidu");
	suffixArray.push("fujitsu");
	suffixArray.push("you");
	suffixArray.push("mit");
	suffixArray.push("now");
	suffixArray.push("meet");
	suffixArray.push("tui");
	suffixArray.push("gmx");
	suffixArray.push("gensberater");
	suffixArray.push("gensberatung");
	suffixArray.push("android");
	suffixArray.push("madrid");
	suffixArray.push("docs");
	suffixArray.push("zuerich");
	suffixArray.push("ggee");
	suffixArray.push("kddi");
	suffixArray.push("lds");
	suffixArray.push("lotte");
	suffixArray.push("mormon");
	suffixArray.push("dclk");
	suffixArray.push("hangout");
	suffixArray.push("cbn");
	suffixArray.push("goldpoint");
	suffixArray.push("yodobashi");
	suffixArray.push("epson");
	suffixArray.push("datsun");
	suffixArray.push("infiniti");
	suffixArray.push("mtpc");
	suffixArray.push("nissan");
	suffixArray.push("guge");
	suffixArray.push("bond");
	suffixArray.push("panerai");
	suffixArray.push("temasek");
	suffixArray.push("honda");
	suffixArray.push("icu");
	suffixArray.push("thd");
	suffixArray.push("homedepot");
	suffixArray.push("montblanc");
	suffixArray.push("lasalle");
	suffixArray.push("drive");
	suffixArray.push("play");
	suffixArray.push("commbank");
	suffixArray.push("scor");
	suffixArray.push("bnl");
	suffixArray.push("bcn");
	suffixArray.push("bharti");
	suffixArray.push("iinet");
	suffixArray.push("sakura");
	suffixArray.push("delta");
	suffixArray.push("airtel");
	suffixArray.push("scholarships");
	suffixArray.push("lixil");
	suffixArray.push("itau");
	suffixArray.push("ceb");
	suffixArray.push("seek");
	suffixArray.push("stcgroup");
	suffixArray.push("viva");
	suffixArray.push("starhub");
	suffixArray.push("bom");
	suffixArray.push("final");
	suffixArray.push("hyundai");
	suffixArray.push("kia");
	suffixArray.push("stockholm");
	suffixArray.push("virgin");
	suffixArray.push("cipriani");
	suffixArray.push("mutuelle");
	suffixArray.push("zara");
	suffixArray.push("playstation");
	suffixArray.push("comsec");
	suffixArray.push("tab");
	suffixArray.push("boehringer");
	suffixArray.push("bostik");
	suffixArray.push("verisign");
	suffixArray.push("cityeats");
	suffixArray.push("firestone");
	suffixArray.push("lifestyle");
	suffixArray.push("nowruz");
	suffixArray.push("pars");
	suffixArray.push("sharp");
	suffixArray.push("shia");
	suffixArray.push("tci");
	suffixArray.push("vana");
	suffixArray.push("creditunion");
	suffixArray.push("lacaixa");
	suffixArray.push("nhk");
	suffixArray.push("gea");
	suffixArray.push("kfh");
	suffixArray.push("watches");
	suffixArray.push("cartier");
	suffixArray.push("iwc");
	suffixArray.push("clinique");
	suffixArray.push("contact");
	suffixArray.push("lamer");
	suffixArray.push("living");
	suffixArray.push("origins");
	suffixArray.push("pid");
	suffixArray.push("safety");
	suffixArray.push("star");
	suffixArray.push("analytics");
	suffixArray.push("sas");
	suffixArray.push("weather");
	suffixArray.push("alibaba");
	suffixArray.push("alipay");
	suffixArray.push("compare");
	suffixArray.push("fresenius");
	suffixArray.push("select");
	suffixArray.push("softbank");
	suffixArray.push("taobao");
	suffixArray.push("tmall");
	suffixArray.push("helsinki");
	suffixArray.push("pamperedchef");
	suffixArray.push("weatherchannel");
	suffixArray.push("flickr");
	suffixArray.push("gallup");
	suffixArray.push("hdfcbank");
	suffixArray.push("health");
	suffixArray.push("pwc");
	suffixArray.push("tvs");
	suffixArray.push("wolterskluwer");
	suffixArray.push("meo");
	suffixArray.push("sapo");
	suffixArray.push("mobily");
	suffixArray.push("telecity");
	suffixArray.push("viking");
	suffixArray.push("kerrylogistics");
	suffixArray.push("kerryproperties");
	suffixArray.push("kuokgroup");
	suffixArray.push("avianca");
	suffixArray.push("chloe");
	suffixArray.push("bcg");
	suffixArray.push("anquan");
	suffixArray.push("piaget");
	suffixArray.push("shouji");
	suffixArray.push("sina");
	suffixArray.push("xihuan");
	suffixArray.push("yun");
	suffixArray.push("abbvie");
	suffixArray.push("abudhabi");
	suffixArray.push("kpmg");
	suffixArray.push("mutual");
	suffixArray.push("northwesternmutual");
	suffixArray.push("vig");
	suffixArray.push("weibo");
	suffixArray.push("adac");
	suffixArray.push("agakhan");
	suffixArray.push("akdn");
	suffixArray.push("ftr");
	suffixArray.push("imamat");
	suffixArray.push("ismaili");
	suffixArray.push("statebank");
	suffixArray.push("baby");
	suffixArray.push("cyou");
	suffixArray.push("jnj");
	suffixArray.push("doha");
	suffixArray.push("mls");
	suffixArray.push("ally");
	suffixArray.push("progressive");
	suffixArray.push("ford");
	suffixArray.push("lincoln");
	suffixArray.push("barefoot");
	suffixArray.push("dds");
	suffixArray.push("gallo");
	suffixArray.push("metlife");
	suffixArray.push("aetna");
	suffixArray.push("flir");
	suffixArray.push("hkt");
	suffixArray.push("nowtv");
	suffixArray.push("pccw");
	suffixArray.push("richardli");
	suffixArray.push("dot");
	suffixArray.push("mlb");
	suffixArray.push("realestate");
	suffixArray.push("dtv");
	suffixArray.push("frontier");
	suffixArray.push("hisamitsu");
	suffixArray.push("locker");
	suffixArray.push("netflix");
	suffixArray.push("ollo");
	suffixArray.push("ott");
	suffixArray.push("pioneer");
	suffixArray.push("ups");
	suffixArray.push("zappos");
	suffixArray.push("apple");
	suffixArray.push("audible");
	suffixArray.push("beats");
	suffixArray.push("chintai");
	suffixArray.push("deal");
	suffixArray.push("epost");
	suffixArray.push("fire");
	suffixArray.push("imdb");
	suffixArray.push("kindle");
	suffixArray.push("prime");
	suffixArray.push("save");
	suffixArray.push("silk");
	suffixArray.push("tdk");
	suffixArray.push("jlc");
	suffixArray.push("kosher");
	suffixArray.push("anz");
	suffixArray.push("orientexpress");
	suffixArray.push("able");
	suffixArray.push("cookingchannel");
	suffixArray.push("dunlop");
	suffixArray.push("farmers");
	suffixArray.push("foodnetwork");
	suffixArray.push("frontdoor");
	suffixArray.push("goodyear");
	suffixArray.push("hgtv");
	suffixArray.push("politie");
	suffixArray.push("travelchannel");
	suffixArray.push("woodside");
	suffixArray.push("icbc");
	suffixArray.push("fedex");
	suffixArray.push("obi");
	suffixArray.push("author");
	suffixArray.push("bot");
	suffixArray.push("cbre");
	suffixArray.push("godaddy");
	suffixArray.push("got");
	suffixArray.push("mitsubishi");
	suffixArray.push("pin");
	suffixArray.push("safe");
	suffixArray.push("fast");
	suffixArray.push("intuit");
	suffixArray.push("joy");
	suffixArray.push("like");
	suffixArray.push("macys");
	suffixArray.push("mint");
	suffixArray.push("nike");
	suffixArray.push("read");
	suffixArray.push("zippo");
	suffixArray.push("room");
	suffixArray.push("zero");
	suffixArray.push("ibm");
	suffixArray.push("beauty");
	suffixArray.push("circle");
	suffixArray.push("comcast");
	suffixArray.push("fujixerox");
	suffixArray.push("homegoods");
	suffixArray.push("homesense");
	suffixArray.push("hsbc");
	suffixArray.push("lancome");
	suffixArray.push("marshalls");
	suffixArray.push("panasonic");
	suffixArray.push("smile");
	suffixArray.push("staples");
	suffixArray.push("tjmaxx");
	suffixArray.push("tjx");
	suffixArray.push("tkmaxx");
	suffixArray.push("winners");
	suffixArray.push("xfinity");
	suffixArray.push("bbt");
	suffixArray.push("bestbuy");
	suffixArray.push("book");
	suffixArray.push("buy");
	suffixArray.push("call");
	suffixArray.push("ieee");
	suffixArray.push("jot");
	suffixArray.push("nationwide");
	suffixArray.push("off");
	suffixArray.push("onyourside");
	suffixArray.push("pfizer");
	suffixArray.push("pohl");
	suffixArray.push("raid");
	suffixArray.push("rightathome");
	suffixArray.push("ses");
	suffixArray.push("swiftcover");
	suffixArray.push("tushu");
	suffixArray.push("wanggou");
	suffixArray.push("alfaromeo");
	suffixArray.push("allstate");
	suffixArray.push("americanfamily");
	suffixArray.push("amfam");
	suffixArray.push("bofa");
	suffixArray.push("booking");
	suffixArray.push("chrysler");
	suffixArray.push("citadel");
	suffixArray.push("dealer");
	suffixArray.push("discover");
	suffixArray.push("esurance");
	suffixArray.push("ferrari");
	suffixArray.push("fiat");
	suffixArray.push("hyatt");
	suffixArray.push("jeep");
	suffixArray.push("juniper");
	suffixArray.push("lefrak");
	suffixArray.push("lpl");
	suffixArray.push("lplfinancial");
	suffixArray.push("mckinsey");
	suffixArray.push("mopar");
	suffixArray.push("pramerica");
	suffixArray.push("pru");
	suffixArray.push("prudential");
	suffixArray.push("smart");
	suffixArray.push("srt");
	suffixArray.push("tiaa");
	suffixArray.push("uconnect");
	suffixArray.push("visa");
	suffixArray.push("abarth");
	suffixArray.push("asda");
	suffixArray.push("athleta");
	suffixArray.push("bananarepublic");
	suffixArray.push("blockbuster");
	suffixArray.push("calvinklein");
	suffixArray.push("capitalone");
	suffixArray.push("cbs");
	suffixArray.push("dish");
	suffixArray.push("dodge");
	suffixArray.push("fidelity");
	suffixArray.push("gap");
	suffixArray.push("goodhands");
	suffixArray.push("hbo");
	suffixArray.push("hot");
	suffixArray.push("hughes");
	suffixArray.push("intel");
	suffixArray.push("lancia");
	suffixArray.push("latino");
	suffixArray.push("loft");
	suffixArray.push("maserati");
	suffixArray.push("mcd");
	suffixArray.push("mcdonalds");
	suffixArray.push("msd");
	suffixArray.push("nba");
	suffixArray.push("oldnavy");
	suffixArray.push("pay");
	suffixArray.push("qvc");
	suffixArray.push("secure");
	suffixArray.push("showtime");
	suffixArray.push("sling");
	suffixArray.push("target");
	suffixArray.push("vivo");
	suffixArray.push("wtc");
	suffixArray.push("aigo");
	suffixArray.push("americanexpress");
	suffixArray.push("amex");
	suffixArray.push("auspost");
	suffixArray.push("boots");
	suffixArray.push("george");
	suffixArray.push("giving");
	suffixArray.push("hdfc");
	suffixArray.push("movistar");
	suffixArray.push("nab");
	suffixArray.push("open");
	suffixArray.push("samsclub");
	suffixArray.push("ubank");
	suffixArray.push("walmart");
	suffixArray.push("lifeinsurance");
	suffixArray.push("diy");
	suffixArray.push("eco");
	suffixArray.push("honeywell");
	suffixArray.push("vanguard");
	suffixArray.push("yamaxun");
	suffixArray.push("mattel");
	suffixArray.push("luxe");
	suffixArray.push("coupon");
	suffixArray.push("spot");
	suffixArray.push("caravan");
	suffixArray.push("song");
	suffixArray.push("tunes");
	suffixArray.push("cal");
	suffixArray.push("chrome");
	suffixArray.push("gle");
	suffixArray.push("nexus");
	suffixArray.push("observer");
	suffixArray.push("wow");
	suffixArray.push("talk");
	suffixArray.push("bauhaus");
	suffixArray.push("budapest");
	suffixArray.push("dvr");
	suffixArray.push("fido");
	suffixArray.push("ikano");
	suffixArray.push("monster");
	suffixArray.push("radio");
	suffixArray.push("rogers");
	suffixArray.push("volvo");
	suffixArray.push("basketball");
	suffixArray.push("baseball");
	suffixArray.push("case");
	suffixArray.push("caseih");
	suffixArray.push("iveco");
	suffixArray.push("ac");
	suffixArray.push("africa");
	suffixArray.push("alstom");
	suffixArray.push("aol");
	suffixArray.push("arab");
	suffixArray.push("arpa");
	suffixArray.push("ax");
	suffixArray.push("banamex");
	suffixArray.push("bnpparibas");
	suffixArray.push("boston");
	suffixArray.push("box");
	suffixArray.push("catholic");
	suffixArray.push("charity");
	suffixArray.push("citi");
	suffixArray.push("cruise");
	suffixArray.push("cw");
	suffixArray.push("data");
	suffixArray.push("duns");
	suffixArray.push("er");
	suffixArray.push("etisalat");
	suffixArray.push("food");
	suffixArray.push("free");
	suffixArray.push("fun");
	suffixArray.push("grocery");
	suffixArray.push("hair");
	suffixArray.push("hospital");
	suffixArray.push("hotels");
	suffixArray.push("ifm");
	suffixArray.push("inc");
	suffixArray.push("je");
	suffixArray.push("jio");
	suffixArray.push("kred");
	suffixArray.push("llc");
	suffixArray.push("map");
	suffixArray.push("merckmsd");
	suffixArray.push("mobile");
	suffixArray.push("moscow");
	suffixArray.push("moto");
	suffixArray.push("newholland");
	suffixArray.push("phd");
	suffixArray.push("phone");
	suffixArray.push("post");
	suffixArray.push("reliance");
	suffixArray.push("ril");
	suffixArray.push("rmit");
	suffixArray.push("rugby");
	suffixArray.push("search");
	suffixArray.push("shaw");
	suffixArray.push("sport");
	suffixArray.push("stada");
	suffixArray.push("sv");
	suffixArray.push("sx");
	suffixArray.push("unicom");
	suffixArray.push("vi");
	suffixArray.push("voting");
	suffixArray.push("xn--11b4c3d");
	suffixArray.push("xn--1ck2e1b");
	suffixArray.push("xn--1qqw23a");
	suffixArray.push("xn--2scrj9c");
	suffixArray.push("xn--30rr7y");
	suffixArray.push("xn--3bst00m");
	suffixArray.push("xn--3ds443g");
	suffixArray.push("xn--3e0b707e");
	suffixArray.push("xn--3hcrj9c");
	suffixArray.push("xn--3oq18vl8pn36a");
	suffixArray.push("xn--3pxu8k");
	suffixArray.push("xn--42c2d9a");
	suffixArray.push("xn--45br5cyl");
	suffixArray.push("xn--45brj9c");
	suffixArray.push("xn--45q11c");
	suffixArray.push("xn--4gbrim");
	suffixArray.push("xn--54b7fta0cc");
	suffixArray.push("xn--55qw42g");
	suffixArray.push("xn--55qx5d");
	suffixArray.push("xn--5su34j936bgsg");
	suffixArray.push("xn--5tzm5g");
	suffixArray.push("xn--6frz82g");
	suffixArray.push("xn--6qq986b3xl");
	suffixArray.push("xn--80adxhks");
	suffixArray.push("xn--80ao21a");
	suffixArray.push("xn--80aqecdr1a");
	suffixArray.push("xn--80asehdb");
	suffixArray.push("xn--80aswg");
	suffixArray.push("xn--8y0a063a");
	suffixArray.push("xn--90a3ac");
	suffixArray.push("xn--90ae");
	suffixArray.push("xn--90ais");
	suffixArray.push("xn--9dbq2a");
	suffixArray.push("xn--9et52u");
	suffixArray.push("xn--9krt00a");
	suffixArray.push("xn--b4w605ferd");
	suffixArray.push("xn--bck1b9a5dre4c");
	suffixArray.push("xn--c1avg");
	suffixArray.push("xn--c2br7g");
	suffixArray.push("xn--cck2b3b");
	suffixArray.push("xn--cg4bki");
	suffixArray.push("xn--clchc0ea0b2g2a9gcd");
	suffixArray.push("xn--czr694b");
	suffixArray.push("xn--czrs0t");
	suffixArray.push("xn--czru2d");
	suffixArray.push("xn--d1acj3b");
	suffixArray.push("xn--d1alf");
	suffixArray.push("xn--e1a4c");
	suffixArray.push("xn--eckvdtc9d");
	suffixArray.push("xn--efvy88h");
	suffixArray.push("xn--estv75g");
	suffixArray.push("xn--fct429k");
	suffixArray.push("xn--fhbei");
	suffixArray.push("xn--fiq228c5hs");
	suffixArray.push("xn--fiq64b");
	suffixArray.push("xn--fiqs8s");
	suffixArray.push("xn--fiqz9s");
	suffixArray.push("xn--fjq720a");
	suffixArray.push("xn--flw351e");
	suffixArray.push("xn--fpcrj9c3d");
	suffixArray.push("xn--fzc2c9e2c");
	suffixArray.push("xn--fzys8d69uvgm");
	suffixArray.push("xn--g2xx48c");
	suffixArray.push("xn--gckr3f0f");
	suffixArray.push("xn--gecrj9c");
	suffixArray.push("xn--gk3at1e");
	suffixArray.push("xn--h2breg3eve");
	suffixArray.push("xn--h2brj9c");
	suffixArray.push("xn--h2brj9c8c");
	suffixArray.push("xn--hxt814e");
	suffixArray.push("xn--i1b6b1a6a2e");
	suffixArray.push("xn--imr513n");
	suffixArray.push("xn--io0a7i");
	suffixArray.push("xn--j1aef");
	suffixArray.push("xn--j1amh");
	suffixArray.push("xn--j6w193g");
	suffixArray.push("xn--jlq61u9w7b");
	suffixArray.push("xn--jvr189m");
	suffixArray.push("xn--kcrx77d1x4a");
	suffixArray.push("xn--kprw13d");
	suffixArray.push("xn--kpry57d");
	suffixArray.push("xn--kpu716f");
	suffixArray.push("xn--kput3i");
	suffixArray.push("xn--l1acc");
	suffixArray.push("xn--lgbbat1ad8j");
	suffixArray.push("xn--mgb9awbf");
	suffixArray.push("xn--mgba3a3ejt");
	suffixArray.push("xn--mgba3a4f16a");
	suffixArray.push("xn--mgba7c0bbn0a");
	suffixArray.push("xn--mgbaakc7dvf");
	suffixArray.push("xn--mgbaam7a8h");
	suffixArray.push("xn--mgbab2bd");
	suffixArray.push("xn--mgbai9azgqp6j");
	suffixArray.push("xn--mgbayh7gpa");
	suffixArray.push("xn--mgbb9fbpob");
	suffixArray.push("xn--mgbbh1a");
	suffixArray.push("xn--mgbbh1a71e");
	suffixArray.push("xn--mgbc0a9azcg");
	suffixArray.push("xn--mgbca7dzdo");
	suffixArray.push("xn--mgberp4a5d4ar");
	suffixArray.push("xn--mgbgu82a");
	suffixArray.push("xn--mgbi4ecexp");
	suffixArray.push("xn--mgbpl2fh");
	suffixArray.push("xn--mgbt3dhd");
	suffixArray.push("xn--mgbtx2b");
	suffixArray.push("xn--mgbx4cd0ab");
	suffixArray.push("xn--mix891f");
	suffixArray.push("xn--mk1bu44c");
	suffixArray.push("xn--mxtq1m");
	suffixArray.push("xn--ngbc5azd");
	suffixArray.push("xn--ngbe9e0a");
	suffixArray.push("xn--ngbrx");
	suffixArray.push("xn--node");
	suffixArray.push("xn--nqv7f");
	suffixArray.push("xn--nqv7fs00ema");
	suffixArray.push("xn--nyqy26a");
	suffixArray.push("xn--o3cw4h");
	suffixArray.push("xn--ogbpf8fl");
	suffixArray.push("xn--otu796d");
	suffixArray.push("xn--p1acf");
	suffixArray.push("xn--pbt977c");
	suffixArray.push("xn--pgbs0dh");
	suffixArray.push("xn--pssy2u");
	suffixArray.push("xn--q9jyb4c");
	suffixArray.push("xn--qcka1pmc");
	suffixArray.push("xn--qxam");
	suffixArray.push("xn--rhqv96g");
	suffixArray.push("xn--rovu88b");
	suffixArray.push("xn--rvc1e0am3e");
	suffixArray.push("xn--s9brj9c");
	suffixArray.push("xn--ses554g");
	suffixArray.push("xn--t60b56a");
	suffixArray.push("xn--tckwe");
	suffixArray.push("xn--tiq49xqyj");
	suffixArray.push("xn--unup4y");
	suffixArray.push("xn--vermgensberater-ctb");
	suffixArray.push("xn--vermgensberatung-pwb");
	suffixArray.push("xn--vhquv");
	suffixArray.push("xn--vuq861b");
	suffixArray.push("xn--w4r85el8fhu5dnra");
	suffixArray.push("xn--w4rs40l");
	suffixArray.push("xn--wgbh1c");
	suffixArray.push("xn--wgbl6a");
	suffixArray.push("xn--xhq521b");
	suffixArray.push("xn--xkc2al3hye2a");
	suffixArray.push("xn--xkc2dl3a5ee0h");
	suffixArray.push("xn--y9a3aq");
	suffixArray.push("xn--yfro4i67o");
	suffixArray.push("xn--ygbi2ammx");
	suffixArray.push("xn--zfr164b");
	suffixArray.push("yt");
	suffixArray.push("ds");
	suffixArray.push("lib");
	suffixArray.push("amazon");
	suffixArray.push("bl");
	suffixArray.push("bq");
	suffixArray.push("cpa");
	suffixArray.push("doosan");
	suffixArray.push("flsmidth");
	suffixArray.push("gay");
	suffixArray.push("htc");
	suffixArray.push("kids");
	suffixArray.push("llp");
	suffixArray.push("mf");
	suffixArray.push("music");
	suffixArray.push("spa");
	suffixArray.push("ss");
	suffixArray.push("um");
	suffixArray.push("pyc");
	suffixArray.push("pirate");
	suffixArray.push("libre");
	suffixArray.push("geek");
	suffixArray.push("oss");
	suffixArray.push("indy");
	suffixArray.push("dyn");
	suffixArray.push("gopher");
	suffixArray.push("parody");
}