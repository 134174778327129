import { Alert } from '@/arco'
import Default from '@/assets/info.svg'
import Success from '@/assets/success.svg'
import Error from '@/assets/error.svg'
import Close from '@/assets/close.svg'
import styles from './index.module.less'

export default function AlertDia(props) {
    const { data: { title, content, button }, type } = props;
    function showIcon() {
        switch (type) {
            case 'success':
                return <Success />
            case 'error':
                return <Error />
            default:
                return <Default />
        }
    }
    return (
        <div className={styles.alertContainer}>
            <div className={styles.header}>
                {showIcon()}
                <div className={styles.right}>
                    {title ? <div className={styles.title}>{title}</div> : <div className={styles.content}>{content}</div>}
                    <Close style={{ color: '#222B40', width: 16, height: 16, cursor:'pointer' }} onClick={() => {
                        Alert.close();
                    }}/>
                </div>
            </div>
            {
                title && content ? <div className={styles.content}>{content}</div> : ""
            }
            {button ? <div className={styles.bottomFooter}>{button}</div> : ""}
        </div>
    )
}
