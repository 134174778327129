export const LANGUAGE = "changeLanguageType"//改变语言类型
export const CONTENTWIDTH = "setContentWidth"//更改内容区域宽度
export const BODYBACK = 'changeBodyBack'//改变页面的背景色
export const ISLOGIN = 'isLogin'//是否在登录页面
export const LOADING = 'loading'//是否显示加载标识
export const SAVEUSERINFO = 'saveUserInfo'//存储用户简单信息
export const SAVEUSERDETAIL = 'saveUserDetail'//存储用户详细信息
export const SETMESSAGECOUNT = 'setMessageCount'//存储用户详细信息
export const ISSHOWGLOBALINPUT = 'isShowGlobalInput'//是否显示全局搜索框
export const UPDATEMENULIST = 'updateMenuList'//是否显示全局搜索框
export const ISIOC = 'isIoc'//是否是ico页面