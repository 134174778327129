import {BODYBACK} from '../constact'

const backColor = 'var(--Elevation-Surface-Subtler)';
export default function bodyBack(preState = backColor, action) {
    const {type, data} = action;
    switch (type) {
        case BODYBACK:
            return data;
        default:
            return preState;
    }
}