import {ISSHOWGLOBALINPUT} from '../constact'

const isShowGlobalInput = true;
export default function isLogin(preState = isShowGlobalInput, action) {
    const {type, data} = action;
    switch (type) {
        case ISSHOWGLOBALINPUT:
            return data;
        default:
            return preState;
    }
}