import React, {useEffect, useState} from 'react'
import { Anchor  } from '@arco-design/web-react'
import './index.less'
const AnchorLink = Anchor.Link;

export default function NewAnchor(props) {
    const [isAn, setIsAn] = useState(false);
    useEffect(() => {
        setIsAn(true);   
    }, [])
    function createTitle(title) {
      const _type = Object.prototype.toString.call(title);
            if (_type === "[object String]") {
              return title
            } else {
              return title.map((item) => {
                  return <span key={item}>{item}</span>
              })
            }
    }
  return <Anchor affix={false} hash={false} className="anchorContainer" animation={isAn}  lineless={false} offsetBottom={100} targetOffset={160} boundary={100} {...props}>
    {
        props.data.map((item, index) => {
            return item.disable ? <AnchorLink 
            // className={item.default ? 'arco-anchor-link-active' : ''} 
            key={index} href={item.href} title={createTitle(item.title)}/> : <div  className={"disable"} key={index}>{item.title}</div>
        })
    }
  </Anchor>
}
