import { Switch } from '@arco-design/web-react'
import cs from 'classnames'
import './index.less'

export default function NewSwitch(props) {
    const _class = {};
    if (props.className) {
        _class[props.className] = true;
    }
  return <Switch type='round' {...props} className={cs('redqueenSwitch', _class)}/>
}
