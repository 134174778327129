import { Button } from '@arco-design/web-react';
import cs from 'classnames'
import './index.less'

export default function ButtonComponent(props) {
    return (
        <Button  {...props}
            className={cs('redqueenButton',props.className, {
                arcoButtonDefault: !props.type || props.type == "default",
                arcoButtonGray: props.type == "gray",
                arcoButtonWhite: props.type == 'white',
                arcoButtonDark: props.type == "dark",
                arcoButtonBlack: props.type == "black",
                
            })}
            type="primary" />
    )
}
