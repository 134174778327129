
import { combineReducers } from 'redux'
import language from './language'
import contentWidth from './contentWidth'
import bodyBack from './setBodyBack'
import isLogin from './isLogin'
import loading from './loading'
import userInfo from './userInfo'
import userDetail from './userDetail'
import messageCount from './messageCount'
import showGlobalInput from './showGlobalInput'
import menuList from './menuList'
import isIoc from './isIoc'

//汇总所有的reducer变为一个总得redecer
export default combineReducers({
    language,
    contentWidth,
    bodyBack,
    isLogin,
    loading,
    userInfo,
    userDetail,
    messageCount,
    showGlobalInput,
    menuList,
    isIoc
})