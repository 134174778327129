import CreatePayDetail from '@/components/ServiceApply'
import CreateHeader from '@/components/ServiceApply/createHeader'
import { Modal,Message, Button} from '@/arco'
import valid from '@/utils/valid'
import language from '@/utils/language'
import Suc from "@/assets/api/suc.svg";
export function applyDialog({ title = "服务申请", width = 540, submit, callback }) {
    let params = {};
    let confirm = Modal({//全局修改标识
        title: <CreateHeader title={title} />,
        content: <CreatePayDetail onChange={(data) => {
            params = data;
        }} />,
        // className: "cardMarketDialog",
        className:'swiopenDialog specialHeightForApply',
        footer:<div style={{width:"100%"}}><Button children={language('public79')} style={{margin: "0 auto", width: 168}} size='large' onClick={async () => {
            if (!params.phone) {
                Message.error(language('user20'))
                return;
            } else if (!valid(params.phone, 9)) {
                // Message.error(language('market33'))
                return;
            }
            if (params.email && !valid(params.email, 5)) {
                // Message.error(language('market35'))
                return;
            }
            const result = await submit({ ...params});
            if(location.pathname.indexOf('api/mock-request')>-1){
                let comfirms =  Modal({
                    title:<div><Suc className='sucIcon'/>{'申请成功'}</div>,
                    content: '您的申请我们已收到，将尽快与您联系。您也可以致电详询：(+86) 400-0810-700。',
                    style: { width: 389 },
                    type: 'alert',
                    closable: true,
                    maskClosable: false,
                    className:'mocksuc',
                    footer:<><Button  onClick={async () =>{comfirms.close()}}>{'我知道了'}</Button></>
                  })

            }else{
                Message.success(result.result.msg);
            }
           
            confirm.close();
            if (callback) {
                callback()
            }
            // props.callback();
        }}/></div>
    })
}