import { useState, useEffect, useRef } from 'react'
import cs from 'classnames'
import { useSelector } from 'react-redux'
import { Badge, Link, Button, Tag } from '@/arco'
import { Spin } from '@arco-design/web-react';
import eventStation from '@/utils/eventStation'
import { queryNumberByType, queryMessageList, setAllIsRead, setNoticeRead } from '@/api/messageCenter'
import Empty from '@/components/Empty'
import SetIcon from '@/assets/message/set.svg'
import Service from '@/assets/message/service.svg'
import Notice from '@/assets/message/notice.svg'
import Close from '@/assets/message/close.svg'
import Clear from '@/assets/message/clear.svg'
import IconDown from '@/assets/message/bdown.svg'
import IconUp from '@/assets/message/bup.svg'
import { IconLeft, IconRight, IconObliqueLine } from '@arco-design/web-react/icon';
import styles from './index.module.less'
// import { useNavigate } from 'react-router-dom'
import language from '@/utils/language'
import { calculateNewValue } from '@testing-library/user-event/dist/utils';
export default function MessageCenter(props) {
    const {languages} = props;
    // const languages =useSelector(state => state.language)
    // const navigate = useNavigate();
    const [dataUpdateNotice, setDataUpdateNotice] = useState(0)
    const [weeklyTiNotice, setWeeklyTiNotice] = useState(0)
    const [businessServicesNotice, setBusinessServicesNotice] = useState(0)
    const [sytemNotice, setSytemNotice] = useState(0)
    const [isCreated, setIsCreated] = useState(false)
    const [params, setParams] = useState({
        type: 1,
        isRead: 0
    })
    const [page, setPage] = useState({
        pageNum: 1,
        pageSize: 10
    })
    const [messageList, setMessageList] = useState([])
    const [messageTotal, setMessageTotal] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        setIsCreated(true)
        setPage({ ...page, pageNum: 1 })
    }, [params])
    useEffect(() => {
        if (isCreated) {
            getMessageList();
        }
    }, [page])
    useEffect(() => {
        getUnreadCountByType()
    }, [])
    //获取消息数据列表
    async function getMessageList() {
        setIsLoading(true)
        const { data, total } = await queryMessageList({ ...params, ...page });
        if (total > 0 && total < messageTotal && !data.length && page.pageNum > 1) {
            setPage({...page, pageNum: page.pageNum - 1})
        }
        eventStation.messageCount()
        getUnreadCountByType()
        const result = data.map((item) => {
            item.showAll = false;
            return item
        })
        setMessageList(result)
        setMessageTotal(total)
        setIsLoading(false)
    }
    //获取不同类型的未读数量
    async function getUnreadCountByType() {
        const { data: { dataUpdateNotice, weeklyTiNotice, businessServicesNotice, systemNotice } } = await queryNumberByType()
        setDataUpdateNotice(dataUpdateNotice)
        setWeeklyTiNotice(weeklyTiNotice)
        setBusinessServicesNotice(businessServicesNotice)
        setSytemNotice(systemNotice)
    }
    function MessageLine({ item, setReadSubmit, showAllOrPart }) {
        const ref = useRef();
        const [height, setHeight] = useState(0);
        const [data, setData] = useState(null);
        const [isRead, setIsRead] = useState(false)
        useEffect(() => {
            setData({ ...item })
        }, [])
        useEffect(() => {
            if (data) {
            // console.log( data.description.replace(/<br>/g, ""));
                setHeight(ref.current.offsetHeight);
            }
        }, [data])
        function setRead(data) {
            if (!isRead) {
                setIsRead(true)
                setReadSubmit(data)
            }
        }
        const isAddTag = data && params.type == 3 && data.title.indexOf(language('message19')) > -1;
        return (
            data ? <li key={data.id}>
                <div className={styles.title}>
                    <div className={cs(styles.left, {[styles.readed]: isRead && params.isRead != 1})} style={{width:languages=='zh'?'':'calc(100% - 134px)'}} onClick={() => {
                        setData({ ...data, showAll: !data.showAll })
                        setRead(data)
                    }} >{
                        isAddTag ? <Tag children={language('message13')} type='error' bordered size="small "/> : ""
                    }<span className={cs({[styles.addTag] : isAddTag})} style={{width:languages=='zh'?'': 'calc(100% - 99px)'}}>{data.title}</span></div>
                    <div className={styles.right}>{data.postTime}</div>
                </div>
                <div className={styles.content}>
                    <div className={styles.contentDetail} style={{ height: data.showAll ? height : 22 }}>
                        <div className={styles.showPart} style={{ display: data.showAll ? 'none' : 'block' }} dangerouslySetInnerHTML={{ __html: data.description ? data.description.replace(/<br>/g, "").replace(/<\/br>/g, "").replace(/<br\/>/g, "") : language('public70') }}/>
                        <div ref={ref} style={{ wordBreak: 'break-all', opacity: data.showAll ? 1 : 0 }} dangerouslySetInnerHTML={{ __html: data.description || language('public70')  }}/>
                    </div>
                    {height > 22 ? <Link className={styles.Linkstyle} onClick={() => {
                        setData({ ...data, showAll: !data.showAll })
                        // showAllOrPart(data);
                        // if (!data.read) {
                        setRead(data)
                        // }
                    }}>{data.showAll ? <>{language('message16')}<IconUp /></> : <>{language('message15')}<IconDown /></>}</Link> : ""}
                </div>
            </li> : ""
        )
    }
    //标记已读
    async function setReadSubmit(item) {
        await setNoticeRead({ id: item.id })
        // const result = messageList.map((data) => {
        //     if (data.id == item.id) {
        //         data.read = true;
        //     }
        //     return data;
        // })
        // setMessageList(result);
    }
    // function showAllOrPart(item) {
    //     const result = messageList.map((data) => {
    //         if (data.id == item.id) {
    //             data.showAll = !data.showAll;
    //         }
    //         return data;
    //     })
    //     setMessageList(result);
    // }
    //生成列表
    function createList() {
        if (isLoading) {
            // return "<Spin size={12} dot></Spin>"
            return <Spin size={23} className={cs("spinBakcColor", 'messageCenterLoading')}></Spin>
        } else {
            if (messageTotal > 0) {
                return <>
                    <ul className={cs(styles.fixedContent, styles.list)}>
                        {messageList.map((item) => {
                            return <MessageLine item={item} key={item.id} setReadSubmit={setReadSubmit} />
                        }
                        )}
                    </ul>
                    <div className={cs(styles.fixedPart, styles.bottomPage)}>
                        <div className={styles.left}>{languages=='zh'?'共'+messageTotal+'条':'Total'+messageTotal}</div>
                        <div className={styles.right}>
                            <Link className={cs(styles.pageStyle, { [styles.disabled]: page.pageNum == 1 })} onClick={() => {
                                if (page.pageNum > 1) {
                                    setPage({ ...page, pageNum: page.pageNum - 1 })
                                }
                            }}><IconLeft /></Link>
                            <div className={cs(styles.pageStyle, styles.curr)}>{page.pageNum}</div>
                            <div className={styles.pageStyle}><IconObliqueLine /></div>
                            <div className={styles.pageStyle}>{Math.ceil(messageTotal / page.pageSize)}</div>
                            <Link className={cs(
                                styles.pageStyle,
                                {
                                    [styles.disabled]: (page.pageNum == Math.ceil(messageTotal / page.pageSize))
                                })
                            } onClick={() => {
                                if (page.pageNum < Math.ceil(messageTotal / page.pageSize)) {
                                    const { pageNum } = page;
                                    setPage({ ...page, pageNum: pageNum + 1 })
                                }
                            }}><IconRight /></Link>
                        </div>
                    </div>
                </>
            } else {
                return <Empty message={language('tipEnandZh8')} type='notice' />
            }

        }
    }
    //全部已读
    async function allIsRead() {
        const result = await setAllIsRead({ type: params.type });
        eventStation.messageCount()
        getUnreadCountByType()
        getMessageList();
    }
    return (
        <div className={styles.messageCenterContainer}>
            <div className={styles.leftMenu} style={{width:languages=='zh'?'':214}}>
                <div className={styles.fixedPart} style={{ padding: 20, height: 64 }}>{language('message1')}</div>
                <ul className={styles.fixedContent}>
                    <li className={styles.removeHoverStyle}>
                        <Service />
                        <div className={styles.title}>{language('message5')}</div>
                        <Badge count={dataUpdateNotice + weeklyTiNotice + businessServicesNotice} dot={true} children={<div />} offset={[6, -2]} />
                    </li>
                    <li className={cs(styles.childPart, { [styles.active]: params.type == 1 })} onClick={() => {
                        if (params.type != 1) {
                            setParams({
                                type: 1,
                                isRead: 0
                            })
                        }
                    }}>
                        <div className={styles.title}>{language('message7')}</div>
                        <Badge count={dataUpdateNotice} children={<div />} offset={[6, 0]} />
                    </li>
                    <li className={cs(styles.childPart, { [styles.active]: params.type == 2 })} onClick={() => {
                        if (params.type != 2) {
                            setParams({
                                type: 2,
                                isRead: 0
                            })
                        }
                    }}>
                        <div className={styles.title}>{language('message8')}</div>
                        <Badge count={weeklyTiNotice} children={<div />} offset={[6, 0]} />
                    </li>
                    <li className={cs(styles.childPart, { [styles.active]: params.type == 3 })} onClick={() => {
                        if (params.type != 3) {
                            setParams({
                                type: 3,
                                isRead: 0
                            })
                        }
                    }}>
                        <div className={styles.title}>{language('message9')}</div>
                        <Badge count={businessServicesNotice} children={<div />} offset={[6, 0]} />
                    </li>
                    <li className={cs({ [styles.active]: params.type == 4 })} onClick={() => {
                        if (params.type != 4) {
                            setParams({
                                type: 4,
                                isRead: 0
                            })
                        }
                    }}>
                        <Notice />
                        <div className={styles.title}>{language('message6')}</div>
                        <Badge count={sytemNotice} dot={true} children={<div />} offset={[6, -2]} />
                    </li>
                </ul>
                <div className={styles.fixedPart}><SetIcon style={{ cursor: "pointer" }} onClick={() => {
                    // navigate('/center/set')
                    window.location.href='/center/set'
                }}/></div>
            </div>
            <div className={styles.rightList} style={{width:languages=='zh'?'':'calc(100% - 217px)'}}>
                <div className={cs(styles.fixedPart, styles.topHeader)}>
                    <div className={cs(styles.leftMenuContainer, { [styles.selected]: params.isRead == 1 },languages=='zh'?'':styles.leftMenuEnContainer)}>
                        <Link children={language('message2')} className={cs(styles.topMenuTemp, { [styles.active]: params.isRead == 0 })} style={{width:languages=='zh'?'':55}} onClick={() => {
                            if (params.isRead == 1) {
                                setParams({ ...params, isRead: 0 })
                            }
                        }} />
                        <Link children={language('message3')} className={cs(styles.topMenuTemp, { [styles.active]: params.isRead == 1 })} style={{width:languages=='zh'?'':55}} onClick={() => {
                            if (params.isRead == 0) {
                                setParams({ ...params, isRead: 1 })
                            }
                        }} />
                    </div>
                    <div className={styles.rightAllRead}>
                        {params.isRead == 0 && messageTotal > 0 ? <Button size="mini" type='gray' onClick={allIsRead}>{language('message4')} <Clear /></Button> : ""}
                        <Close style={{ cursor: "pointer" }} onClick={() => {
                            props.close()
                        }} />
                    </div>
                </div>
                {createList()}
            </div>
        </div>
    )
}
