import { Table } from "@arco-design/web-react"
import { Popover } from '@/arco'
// import cs from 'classnames'
import './index.less'
import '../Pagination/index.less'

export default function TableComponent(props) {
    props.columns.map((item) => {
        item.ellipsis = item.ellipsis === false ? item.ellipsis : true;
        item.render = item.render || function (result) {
            return <Popover title={result} />
        }
    })
    function isShowPagination() {
        if (props.pagination === false) {
            return false;
        } else {
            return {
                className: "paginationCustomization",
                showTotal: true,
                showJumper: true,
                sizeCanChange: true,
                hideOnSinglePage: false,
                pageSize: props.pagination.pageSize,
                current: props.pagination.current,
                total: props.pagination.total,
                sizeOptions: props.pagination.sizeOptions || [5, 10, 20, 50, 100, 200],
                ...props.pagination
            }
        }
    }
    return <div className={"redqueenTable"}><Table {...props}
        pagination={isShowPagination()} /></div>
}