import React, { useState } from 'react'
import { Input } from '@arco-design/web-react'
import SearchButtonContext from './SearchButtonContext'
import cs from 'classnames'
import './index.less'
// import Search from '@/assets/threat/search.svg'
// import { IconClockCircle, IconSearch, IconInfoCircle } from '@arco-design/web-react/icon'
import IconSearch from '@/assets/IconSearch.svg'
const TextArea = Input.TextArea;
export function NewInput(props) {
    const newProps = { ...props, r: null }
    if (props.type == "password") {
        return (<div style={{ position: "relative", width: "100%" }}>
            <Input.Password  autoComplete="new-password" visibilityToggle={true}  {...newProps} ref={props.r} className={cs("redqueenInput", { errorStyle: props.error }, typeof props.className == 'string' ? props.className : (typeof props.className == 'object' ? { ...props.className } : ""))} />
            {props.error && props.errormsg ? <div className={cs("inputErrorMsg", { [props.errormsgStyle]: props.errormsgStyle })}>{props.errormsg}</div> : ""}
        </div >
        )
    } else {
        return (<div style={{ position: "relative", width: "100%" }}>
            <Input {...newProps} ref={props.r} autoComplete="off" className={cs("redqueenInput", { errorStyle: props.error }, typeof props.className == 'string' ? props.className : (typeof props.className == 'object' ? { ...props.className } : ""))} />
            {props.error && props.errormsg ? <div className={cs("inputErrorMsg", { [props.errormsgStyle]: props.errormsgStyle })}>{props.errormsg}</div> : ""}
        </div>
        )
    }
}

export function NewTextarea(props) {
    const [hover, setHover] = useState(false);
    const [focus, setFocus] = useState(false);
    if (props.max) {
        return <div className={cs('redqueenTextareaMax', props.className, { textareHover: hover, textareaFocus: focus })} onMouseEnter={() => {
            setHover(true)
        }} onMouseLeave={() => {
            setHover(false)
        }} >
            <TextArea {...props} style={{ height: props.height || 'auto' }} className={cs("redqueenTextarea")} onFocus={() => {
                setFocus(true)
            }} onBlur={() => {
                setFocus(false)
            }} />

            <div className="valueCount">{props.size || 0} / {props.max}</div>
        </div>
    } else {
        return <TextArea {...props} className={cs("redqueenTextarea", props.className)} />
    }
}
export function NewInputSearch(props) {
    // const InputSearch = Input.Search;
    // function crateSearchButton() {
    //     if (props.setsearchbutton) {
    //         return { searchButton: <SearchButtonContext data={props.setsearchbutton} /> }
    //     } else {
    //         return {searchButton:false}
    //     }
    // }
    const propsList = { ...props };
    delete propsList.onSearch;
    return (
        <Input allowClear {...propsList} onPressEnter={props.onSearch} className={cs("redqueenInputSearch", props.className)} suffix={<IconSearch style={{ fill: "red" }} onClick={props.onSearch} />} />
    )
}

