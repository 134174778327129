import { DatePicker } from '@arco-design/web-react';
import './index.less'

export function NewRangePicker(props) {
    const { RangePicker } = DatePicker;
    return <RangePicker {...props} className="redqueenDatePicker"/>
}
export function NewDatePicker(props) {
    return <DatePicker {...props} />
}

