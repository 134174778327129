import {ISIOC} from '../constact'

const isIocInit = false;
export default function isIoc(preState = isIocInit, action) {
    const {type, data} = action;
    switch (type) {
        case ISIOC:
            return data;
        default:
            return preState;
    }
}