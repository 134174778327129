import { Tag } from '@arco-design/web-react'
import {Popover} from '@/arco'
import cs from 'classnames'
import './index.less'

export default function NewTag(props) {
    return (
        <Tag
            {...props}
            // children={props.children ? <Popover title={props.children}/> : ""}
            children={props.children || ""}
            className={cs("redqueenTag", props.className,{
                styleDefault: !props.type || props.type == 'default',
                styleError: props.type == 'error',
                styleSuccess: props.type == 'success',
                styleWarning: props.type == 'warning',
                styleBlue: props.type == 'blue'
            })} />
    )
}
