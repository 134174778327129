import { Modal } from '@arco-design/web-react'
import { Button } from '@/arco'
import CloseIcon from '@/assets/CloseIcon.svg'
import cs from 'classnames'
import './index.less'
import language from '@/utils/language'
export default function NewModal(props) {
    // const a = <Button children="确认"/>
    const confirm = Modal.confirm({
        icon:null,
        closeIcon:<CloseIcon/>,
        simple: false,
        footer:<><Button children={language('public64')} onClick={props.onOk}/><Button type="gray" children={language('public65')} onClick={() => {
            confirm.close();
        }}/></>,
        ...props,
        className:cs("redqueenModalContainer", props.className, {redqueenAlertModal: props.type == 'alert'})
    })
    return confirm;
}