import {CONTENTWIDTH} from '../constact'

const widthInit = 1320;
export default function contentWidth(preState = widthInit, action) {
    const {type, data} = action;
    switch (type) {
        case CONTENTWIDTH:
            return data;
        default:
            return preState;
    }
}