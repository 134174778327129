import {ISLOGIN} from '../constact'

const isLoginInit = false;
export default function isLogin(preState = isLoginInit, action) {
    const {type, data} = action;
    switch (type) {
        case ISLOGIN:
            return data;
        default:
            return preState;
    }
}