import request from '@/api/request'

export const querySummarizationData = params => request('homeIndexSummarizationData', {params})
export const queryImportIntelList = params => request('homeIndexImportInteListHttp', {params})
export const queryHoleIntelList = params => request('homeIndexHoleIntelListHttp', {params})
export const queryIocList = params => request('homeIndexIocListHttp', {params})
export const icoListDownload = params => request('homeIndexIocLisDownloadtHttp', {params})
export const querySafeInfoList = params => request('homeIndexSafeInfoListHttp', {params})
export const queryDataCollection = params => request('homeIndexDataCollectionHttp', {params})
export const querySearchCriteria = params => request('homeIndexSearchCriteriaHttp', {params, loading: false})
export const deleteHistoryRecord = params => request('homeIndexSearchDeleteHistoryHttp', {params})
export const queryReportList = params => request('homeIndexReportListHttp', {params})
export const querySecurityList = params => request('homeSecurityInfoListHttp', {params})
export const querySecurityDetail = params => request('homeSecurityInfoDetailHttp', {params})
//全局搜索
export const queryGloalSearchIntel = params => request('homeSearchGlobalIntellHttp', {params})
export const queryGloalSearchInfo = params => request('homeSearchGlobalInfoHttp', {params})
export const queryGloalSearchHole = params => request('homeSearchGlobalHoleHttp', {params})
export const queryGloalSearchOrg = params => request('homeSearchGlobalOrgHttp', {params})
export const queryGloalSearchMalware = params => request('homeSearchGlobalMalwareHttp', {params})
export const queryGloalSearchTool = params => request('homeSearchGlobalToolHttp', {params})
export const queryGloalSearchAttack = params => request('homeSearchGlobalAttackHttp', {params})
export const queryGloalSearchSafety = params => request('homeSearchGlobalSafetyHttp', {params})
export const queryInfoDetailData = params => request('homeInfoDetailDataHttp', {params})
export const downLoadInfoDetail = params => request('homeInfoDownloadHttp', {params, returnAll: true})
export const queryIocData = params => request('homeInfoDetailIcoHttp', {params})
export const downloadIocSubmit = params => request('homeInfoDetailIocDownloadHttp', {params})
export const queryInfoDataList = params => request('homeInfoDetailInfoDataHttp', {params})
export const addHistoryRecord = params => request('homeIndexSearchAddHistoryHttp', {params, loading: false})

//高级情报
export const queryAdvanceIntelleList = params => request('homeAdvanceIntellDataHttp', {params,loading: false})
export const queryHoleIntelleList = params => request('homeAdvanceHoleInfoDataHttp', {params,loading: false})
