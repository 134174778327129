import { LANGUAGE } from '../constact'
import getCookie from '@/utils/getCookie'

// const languageTypeInit = localStorage.getItem("languageType") || "zh"
const languageTypeInit = getCookie("weblanguage") || "zh"
export default function language(preState = languageTypeInit, action) {
    const { type, data } = action;
    switch (type) {
        case LANGUAGE:
            return data;
        default:
            return preState
    }
}