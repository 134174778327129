import React from 'react'
import {useSelector} from 'react-redux'
import FooterLogo from '@/assets/footerLogo.svg'
import FooterEnLogo from '@/assets/footerEnLogo.svg'
import Code from '@/assets/code.svg'
import styles from './index.module.less'
import language from '@/utils/language'

export default function Footer() {
  const languages =useSelector(state => state.language)
  const contentWidth = useSelector(state => state.contentWidth)
  return (
    <div className={styles.footerBody}>
      <div className={styles.middleContainer} style={{width:contentWidth}}>
        <div className={styles.topList}>
          <div className="topLogo" style={{height:38}}>
            {languages=='zh'?<FooterLogo />:<FooterEnLogo />}
          </div>
          <ul className={styles.infoList}>
            <li className={styles.list}>
              <div className={styles.title}>{language("footer1")}</div>
              <div className={styles.content}>
                <div className={styles.columnTemplate}>
                  <div className={styles.lineStyle} onClick={() => {
                    window.open('/home');
                  }}><span>{language("public45")}</span><small>RedQueen</small></div>
                  <div className={styles.lineStyle} onClick={() => {
                    window.open('https://www.tj-un.com/leon.html');
                  }}><span>{language("public46")}</span><small>TIG</small></div>
                </div>
                <div className={styles.columnTemplate}>
                  <div className={styles.lineStyle} onClick={() => {
                    window.open('https://drps.tj-un.com/');
                  }}><span>{language("public47")}</span><small>DRP</small></div>
                  <div className={styles.lineStyle} onClick={() => {
                    window.open('https://www.tj-un.com/sic.html');
                  }}><span>{language("public48")}</span><small>SIC</small></div>
                </div>
              </div>
            </li>
            <li className={styles.list}>
              <div className={styles.title}>{language("footer6")}</div>
              <div className={styles.content}>
                <div className={styles.columnTemplate}>
                  <div><span>{language("public50")}</span><small>(+86)400-0810-700</small></div>
                  <div><span>{language("public52")}</span><small>mkt@tj-un.com</small></div> 
                </div>
                <div className={styles.columnTemplate}>
                  <div><span>{language("public51")}</span><small>service@tj-un.com</small></div>
                  <div><span>{language("public53")}</span><small>partner@tj-un.com</small></div>
                </div>
              </div>
            </li>
            <li className={styles.companyCode}>
              <Code style={{float:"right", marginBottom:"8px",marginRight:languages=='zh'?'':24}}/>
              <div className={styles.div}>{language("footer11")}</div>
            </li>
          </ul>
        </div>
        <div className={styles.bottomInfo}>
          <div className={styles.rowTemp}>
            <span>{languages=='zh'?'北京天际友盟信息技术有限公司版权所有':'Tianji Partners Co., Ltd. All Rights Reserved'}</span>
            <span>Copyright © TianJi Partners Website 2024</span>
            <span>{language('public55')}</span>
          </div>
          {/* <div className={styles.rowTemp}>
          </div> */}
        </div>
      </div>
    </div>
  )
}
