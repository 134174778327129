import { SAVEUSERDETAIL } from '../constact'

const userInit = {};
export default function userInfo(preState = userInit, action) {
    const {type, data} = action;
    switch (type) {
        case SAVEUSERDETAIL:
            return data;
        default:
            return preState;
    }
}