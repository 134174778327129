import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Spin } from '@arco-design/web-react';
import { Button, Popover } from '@/arco'
import saveUserAction from '@/redux/actions/userInfo'
import userDetail from '@/redux/actions/userDetail'
import { userInfoDetail } from '@/api/common'
import { logout } from '@/api/login'
import CUser from '@/assets/center/cUser.svg'
import CuserEn from '@/assets/center/cUser_en.svg'
import Header from '@/assets/center/header.svg'
import TurnRight from '@/assets/center/turnRight.svg'
import styles from './index.module.less'
import { applyDialog } from '@/utils/public'
import { serviceApply } from '@/api/center'
import eventStation from '@/utils/eventStation'
import language from '@/utils/language'
import cs from 'classnames'
export default function UserInfo() {
    const languages = useSelector(state => state.language)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userInfo = useSelector(state => state.userDetail)
    useEffect(() => {
        querUserInfoDetail();
    }, [])
    async function querUserInfoDetail() {
        const result = await userInfoDetail();
        dispatch(userDetail(result.data))
    }
    function showSourceCount(data) {
        if (data) {
            const newArr = data.split('/');
            if (newArr.length === 1) {
                return data
            } else {
                const count = newArr.shift();
                return <><small>{count}</small>/{newArr.join("/")}</>
            }
        } else {
            return language('user10')
        }
    }
    async function logoutCannel() {
        await logout();
        dispatch(saveUserAction({loginStatus:0}))
        navigate('/home')
        eventStation.updateMenuList();
    }
    function serviceTime() {
        if (userInfo.expirationDate) {//付费用户
            return (
                <>
                    <div className={styles.left}>
                        <div className={styles.top}>{language('user311')}</div>
                        <div className={styles.bottom}>{userInfo.expirationDate}</div>
                    </div>
                </>
            )
        } else {//免费用户
            return (
                <>
                    <div className={styles.left}>
                        <div className={styles.top}>{language('user311')}</div>
                        <div className={styles.bottom}>{language('user5')}</div>
                    </div>
                    <div className={styles.right}>
                        <Button children={language('user63')} size="small" onClick={() => {
                            applyDialog({
                                title: language('tipEnandZh11'),
                                submit: serviceApply
                            })
                        }} />
                    </div>
                </>
            )
        }
    }
    // 我的订阅跳转用户中心的我的订阅
    function subscriptionTurn() {
        if (userInfo.subscribedCard || userInfo.subscribedCard == 0) {
            navigate('/center/subscription')
        }
    }
    //用户名跳转用户中心的基础信息
    function userCenterBasicInfo() {
        navigate('/center')
    }
    //生成用户类型
    function createUserType() {
        if (userInfo.userLogoType == 2) {
            return <div className={styles.userType}>
                {languages=='zh' ? <CUser style={{ position: 'relative', left: -10, top: -12 }} /> : <CuserEn />}
            </div>
        } else {
            return ''
        }
    }
    return (
        <Spin loading={!userInfo.fullname} size={23} className='spinBakcColor'>
            <div className={styles.userInfoContainer} style={{ opacity: userInfo ? 1 : 0 }}>
                <div className={styles.topList}>
                    <div className={styles.userInfo} onClick={userCenterBasicInfo}>
                        <div style={{ width: 48, height: 48 }}><Header style={{ position: 'relative', left: -15, top: -15 }} /></div>

                        {createUserType()}
                        <div className={styles.info}>
                            <Popover className={styles.top} title={userInfo.username} />
                            <Popover className={styles.bottom} title={userInfo.email} />
                        </div>
                    </div>
                    <div className={styles.servicePart}>
                        {serviceTime()}
                    </div>
                    <div className={styles.sourceCount}>
                        <div className={styles.count}>{language('user7')}</div>
                        <ul className={styles.detail}>
                            <li><div>{language('user8')}：</div><div className={styles.contralWidth} style={{width:languages=='zh'?'':'calc(100% - 100px)'}}>{showSourceCount(userInfo.webQuery)}</div></li>
                            <li><div>{language('user9')}：</div><div className={styles.contralWidth} style={{width:languages=='zh'?'':'calc(100% - 100px)'}}>{showSourceCount(userInfo.apiQuery)}</div></li>
                        </ul>
                    </div>
                    <div className={cs(styles.subscription, {[styles.addHoverStyle]: (userInfo.subscribedCard || userInfo.subscribedCard == 0)})} onClick={subscriptionTurn}>{language('user4')}：{(userInfo.subscribedCard || userInfo.subscribedCard == 0) ? <span style={{color: "var(--Color-Text-Brand-Hovered, #BD2E3C)",fontWeight: 500, fontSize:14}}>{userInfo.subscribedCard}<TurnRight style={{ float: 'right', marginTop: 3 }} /></span> : language('user46')}</div>
                </div>
                <Button children={language('user601')} type="white" size="large" onClick={logoutCannel} />
            </div>
        </Spin >
    )
}
