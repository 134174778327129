import {SETMESSAGECOUNT} from '../constact'

const countInit = 0;
export default function messageCount(preState = countInit, action) {
    const {type, data} = action;
    // console.log(type, data);
    switch (type) {
        case SETMESSAGECOUNT:
            return data;
        default:
            return preState;
    }
}