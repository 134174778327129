/**
 * 此方法可根据所传的key值直接生成不同语言类型下的展示内容
 * 用法：import Language from '@/utils/Languag"
 *       Language("demo")
 */
import eventStation from './eventStation';
import languageList from './languageList'
// import { useSelector } from 'react-redux'
export default function language(key, time) {
    const language = eventStation.lang
    if (time) {
        const res = languageList[key](time);
        return res[language];
    }
    // return languageList[key][language]
    return languageList[key][language]
}