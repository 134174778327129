import {UPDATEMENULIST} from '../constact'

const menuInit = [];
export default function menuList(preState = menuInit, action) {
    const {type, data} = action;
    switch (type) {
        case UPDATEMENULIST:
            return data;
        default:
            return preState;
    }
}