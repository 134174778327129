import { useEffect } from 'react'
import { Checkbox } from '@arco-design/web-react'
import cs from 'classnames'
import './index.less'

export default function NewCheckBox(props) {
    // if (props.options) {
    let _options = {};
    const {
        selected,
        selectAll,
        unSelectAll,
        setSelected,
        isPartialSelected,
        isAllSelected
    } = Checkbox.useCheckbox(
        props.options.map((x) => x.value),
        props.defaultValue
    );
    const CheckboxGroup = Checkbox.Group;
    const ShowSelectAll = props.ShowSelectAll
    function isShowSelectAll() {
        if (props.ShowSelectAll) {
            return <Checkbox
                onChange={(checked, e) => {
                    let result = []
                    if (checked) {
                        selectAll();
                        result = props.options.map((item) => {
                            return item.value
                        })
                    } else {
                        unSelectAll();
                        result = [];
                    }
                    props.onChange(result);
                }}
                checked={isAllSelected()}
                indeterminate={isPartialSelected()}
            >
                {(checked) => {
                    return <ShowSelectAll type={checked.checked ? "error" : "default"} />
                }}

            </Checkbox>
        }
    }
    return <>{isShowSelectAll()}<CheckboxGroup value={selected} {...props} className={cs("redqueenCheckbox", props.className)} onChange={(value) => {
        setSelected(value)
        console.log();
        props.onChange(value);
    }} /></>
    // } else {
    //     return <Checkbox {...props} className={cs("redqueenCheckbox", props.className)} />
    // }
}
