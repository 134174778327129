import { useState, useRef, useEffect } from 'react'
import { Button, Tag, Popover } from '@/arco'
import { Input, Message, Alert } from '@arco-design/web-react'
import { useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import IconSearch from '@/assets/IconSearch.svg'
import Search from '@/assets/threat/search.svg'
import Delete from '@/assets/home/delete.svg'
import GragSearch from '@/assets/home/graySearch.svg'
import eventStation from '@/utils/eventStation'
import AlertComponent from '@/components/Alert'
import valid from '@/utils/valid'
import cs from 'classnames'
// import { deleteHistoryRecord } from '@/api/home/index'
import styles from './index.module.less'
import language from '@/utils/language'
import { querySearchCriteria, deleteHistoryRecord, addHistoryRecord } from '@/api/home/index'

export default function HomeInputSearch(props) {
    const languages = useSelector(state => state.language)
    const { type } = props;
    const inputSearch = useRef();
    const navigate = useNavigate();
    const location = useLocation();
    // const [] = useState()
    const isSpecialHeader = location.pathname == '/knowledge/treat-Img'
    // const language = useSelector(state => state.language)
    const userInfo = useSelector(state => state.userInfo)
    // const { data: { intelligenceTag = [], latestSearch = [], hotEvent = [] }, dataUpdate } = props;
    const ref = useRef();
    const [isMouseover, setIsMouseover] = useState(false);
    const [isFocus, setIsFocus] = useState(false);
    const [searchHeight, setSearchHeight] = useState(type ? 36 : 52);
    const [keyword, setKeyword] = useState("")
    const [searchCriteria, setSearchCriteria] = useState({})//搜索框下拉条件
    const [searchType, setSearchType] = useState(null)//已选搜索类型
    const [searchTypeList] = useState([{
        threat_id: "1",
        threat_name: language('home73')
    }, {
        threat_id: "2",
        threat_name: language('public23')
    }, {
        threat_id: "4",
        threat_name: language('public24')
    },
    //  {
    //     threat_id: "3",
    //     threat_name: "安全通告"
    // }, 
    {
        threat_id: "5",
        threat_name: language('home6')
    }, {
        threat_id: "8",
        threat_name: language('home9')
    }, {
        threat_id: "7",
        threat_name:  language('home8')
    }, {
        threat_id: "6",
        threat_name: language('home7')
    }])
    useEffect(() => {
        if (searchHeight != (type ? 36 : 52)) {
            setSearchHeight(ref.current.offsetHeight + (type ? 36 : 52));
        }
    }, [searchType, searchCriteria])
    useEffect(() => {
        getSearchCriteria();
    }, [])
    //删除历史搜索
    async function deleteRecord() {
        const result = await deleteHistoryRecord();
        Message.success(language('subscription144'))
        setSearchCriteria({ ...searchCriteria, latestSearch: [] })
    }
    //搜索非ioc跳转
    function searchDefaultKeyword(_keyWord) {
        if (userInfo.loginStatus == 1) {//已登录
            navigate('/home/search', {
                state: {
                    type: searchType,
                    keyword: _keyWord
                }
            })
        } else {//未登录 
            navigate('/login', {
                state: {
                    path: '/home/search',
                    state: {
                        type: searchType,
                        keyword: _keyWord
                    }
                }
            })
        }
    }
    //搜索
    async function homeSearchSubmit(history) {
        const _keyWord = history || keyword.trim();
        const result = await addHistoryRecord({ keyword: _keyWord });
        const isIoc = valid(_keyWord, 1) || valid(_keyWord, 2) || valid(_keyWord, 3) || valid(_keyWord, 4) || valid(_keyWord, 5) || valid(_keyWord, 6) || valid(_keyWord, 7) || valid(_keyWord, 10);
        if (_keyWord && eventStation.checkTrim(_keyWord)) {
            if (valid(_keyWord, 10)) {//ipv6
                Message.success({
                    showIcon:false,
                    content: <AlertComponent data={{content:language('public80')}} type='error'/>,
                    duration: 2000,
                    className:"redqueenAlert error",
                })
                return;
            }
            if (isIoc && !searchType) {//HASH
                eventStation.showcaptcha(_keyWord)
            } else {
                searchDefaultKeyword(_keyWord)
            }
            setKeyword("")
            setIsFocus(false);
            if (inputSearch.current) {
                inputSearch.current.blur()
            }
        } else {
            Message.error(language('public73'))
        }
    }
    //历史记录删除前
    function deleteCallbackBefore() {
        inputSearch.current.focus();
        deleteRecord();
    }
    //历史纪录点击
    function historySearchStart(keyword) {
        setKeyword(keyword);
        homeSearchSubmit(keyword);
    }
    //下拉搜索条件
    async function getSearchCriteria() {
        const { data } = await querySearchCriteria();
        // console.log({...data});
        setSearchCriteria({ ...data })
    }
    function setWidth() {
        if (type) {
            if (searchHeight == 36) {
                return {
                    width: 380
                }
            } else {
                return {
                    width: 708
                }
            }
        } else {
            return {};
        }
    }
    return (
        <div className={cs(styles.homeInputSearchContainer, props.className, { [styles.topPosition]: type, [styles.specialHeader]: isSpecialHeader })} style={setWidth()}>
            <div className={cs(styles.searchInputContainer)} style={{ height: searchHeight }} onMouseEnter={() => {
                setIsMouseover(true);
            }} onMouseLeave={() => {
                setIsMouseover(false);
                if (!isFocus) {
                    setSearchHeight(type ? 36 : 52);
                }
            }}>
                <div className={styles.topInputPart}>
                    <div className={styles.leftInput} style={{width:languages=='zh'?'':'calc(100% - 110px)'}}>
                        {
                            searchType ? <Tag style={{ marginRight: 0 }} className={styles.selectedTypeStyle} children={searchType.threat_name} size={type ? 'small' : 'large'} closable onClose={() => {
                                setSearchType(null)
                            }} /> : ""
                        }
                        <div className={styles.inputContainer}>
                            <Input style={{ height: "22px!important" }} className={cs('redqueenInput', styles.input)} ref={inputSearch} allowClear value={keyword} placeholder={type ? language('home77') : language('home27')} onPressEnter={() => {
                                homeSearchSubmit()
                            }} onFocus={() => {
                                setSearchHeight(ref.current.offsetHeight + (type ? 36 : 52));
                                setIsFocus(true);
                                getSearchCriteria()
                            }} onBlur={() => {
                                setIsFocus(false);
                                if (!isMouseover) {
                                    setSearchHeight(type ? 36 : 52);
                                }
                            }} onChange={(keyword) => {
                                setKeyword(keyword);
                            }} />
                        </div>
                    </div>
                    {type ? <Search style={{ cursor: "pointer" }} onClick={() => {
                        homeSearchSubmit()
                    }} /> : <Button children={language('home21')} size="large" icon={<IconSearch />} onClick={() => {
                        homeSearchSubmit()
                    }} />}
                </div>
                <ul className={styles.searchParamsList} ref={ref}>
                    <li>
                        <div className={styles.header}>{language('home22')}</div>
                        <div className={styles.content}>
                            {
                                searchTypeList.map((item) => {
                                    return <Tag key={item.threat_id} children={item.threat_name} icon={<GragSearch />} className={styles.searchTemp} size="medium" onClick={() => {
                                        setSearchType({ ...item })
                                    }} />
                                })
                            }

                            {/* <Tag children="漏洞情报" icon={<GragSearch />} className={styles.searchTemp} size="medium" />
                            <Tag children="安全资讯" icon={<GragSearch />} className={styles.searchTemp} size="medium" />
                            <Tag children="安全通告" icon={<GragSearch />} className={styles.searchTemp} size="medium" />
                            <Tag children="威胁组织" icon={<GragSearch />} className={styles.searchTemp} size="medium" />
                            <Tag children="攻击模式" icon={<GragSearch />} className={styles.searchTemp} size="medium" />
                            <Tag children="工具" icon={<GragSearch />} className={styles.searchTemp} size="medium" />
                            <Tag children="恶意软件" icon={<GragSearch />} className={styles.searchTemp} size="medium" /> */}
                        </div>
                    </li>
                    {
                        //未登录状态不展示历史搜索
                        userInfo.loginStatus == 1 && searchCriteria.latestSearch && searchCriteria.latestSearch.length ? <li>
                            <div className={styles.header}>{language('home23')}<Delete onClick={deleteCallbackBefore} /></div>
                            <div className={cs(styles.content, styles.searchHistory)}>
                                {
                                    searchCriteria.latestSearch.map((item, index) => {
                                        return <Popover key={index} title={item} className={styles.searchHistoryTemp} onClick={() => {
                                            historySearchStart(item)
                                        }} />
                                    })
                                }
                            </div>
                        </li> : ""
                    }

                    {
                        //指定搜索类型不展示推荐情报和热门事件
                        searchType ? "" : <><li>
                            <div className={styles.header}>{language('home24')}</div>
                            <div className={styles.content}>
                                {
                                    searchCriteria.intelligenceTag ? searchCriteria.intelligenceTag.map((item, index) => {
                                        return <Tag key={index} children={item[eventStation.lang]} bordered className={styles.searchTemp} size="medium" onClick={() => {
                                            historySearchStart(item[eventStation.lang])
                                        }} />
                                    }) : ""
                                }
                            </div>
                        </li>
                            <li>
                                <div className={styles.header}>{language('home25')}</div>
                                <div className={cs(styles.content, styles.topicalEvent)}>
                                    {
                                        searchCriteria.hotEvent ? searchCriteria.hotEvent.map((item, index) => {
                                            return <div key={index} className={cs(styles.topicalTemp, { [styles.hight]: item.color == 1, [styles.blue]: item.color == 2, })} onClick={() => {
                                                if (eventStation.isIoc(item.ioc)) {
                                                    eventStation.showcaptcha(item.ioc)
                                                } else {
                                                    searchDefaultKeyword(item.ioc);
                                                };
                                                // 
                                            }}>
                                                <Popover title={item.ioc} className={styles.hotTitle} />
                                                <div className={styles.count}>{language('home26')} {item.reputation}</div>
                                            </div>
                                        }) : ""
                                    }
                                </div>
                            </li></>
                    }

                </ul>
            </div>
        </div>
    )
}
