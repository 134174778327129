import { SAVEUSERINFO } from '../constact'

const userInit = {loginStatus:0,loading:true}
export default function userInfo(preState = userInit, action) {
    const {type, data} = action;
    switch (type) {
        case SAVEUSERINFO:
            return data;
        default:
            return preState;
    }
}