import {useState, useEffect} from 'react'
import { Form } from '@arco-design/web-react';
import { FormItem, Input,TextArea } from '@/arco'
import { useDispatch,useSelector} from 'react-redux'
import styles from './index.module.less'
import language from '@/utils/language'
import eventStation from '@/utils/eventStation'
import valid from '@/utils/valid'
import cs from 'classnames'
export default function CreatePayDetail(props) {
    
    const [form] = Form.useForm();
    //购买信息
    const [applyParams, setApplyParams] = useState({
      phone: "",
      email: "",
      remark: ""
    })
    const [phoneError, setPhoneError] = useState("")
    const [emailError, setEmailError] = useState("")
    useEffect(() => {
      props.onChange(applyParams);
    }, [applyParams])
    return <div className={styles.subDialogContainer}>
      {eventStation.lang=='zh'?<div className={styles.header} style={{ margin: "10px 0 22px" }}>请填写如下联系信息，确认申请后，将会有相应市场人员与您沟通相应商务流程。<br/>如有疑问，可咨询<span> (+86) 400-0810-700</span> 或 <span>service@tj-un.com</span></div>
      :<div className={styles.header} style={{ margin: "10px 0 22px" }}>Please fill in the following information, and then our staff will contact you soon. <br/>If you have any questions, please call<span> (+86) 400-0810-700</span> or write an email to <span>service@tj-un.com</span></div>}
      <Form
        onValuesChange={(changeValue, values) => {
          if (Object.keys(changeValue)[0] == 'remark' && changeValue.remark.length > 200) {
            changeValue.remark = changeValue.remark.substring(0, 200);
            form.setFieldValue('remark', changeValue.remark);
          }
          setApplyParams({ ...applyParams, ...changeValue });
        }}
        form={form}
        initialValues={applyParams}
        className="applyPayDialog"
      >
        <div className='modalFormLineTemp'>
        <FormItem field="phone" label={language('market32')} labelCol={{ span: eventStation.lang=='zh'?5 : 12 }}  required={true}>
          <Input placeholder={language('user20')}  className={cs({ modalErrorStyle: phoneError })} onBlur={(value) => {
                if (applyParams.phone && !valid(applyParams.phone, 9)) {
                  setPhoneError(language("market33"))
                } else {
                  setPhoneError("")
                };
            }}/>
        </FormItem>
          <div className='modalError' style={{ display: phoneError ? "block" : "none" }}>{phoneError}</div>
        </div>
        <div className='modalFormLineTemp'>
        <FormItem field="email" label={language('market34')} labelCol={{ span: eventStation.lang=='zh'?5 : 12 }}   style={{textAlign:"left"}}>
          <Input placeholder={language('user67')}  className={cs({ modalErrorStyle: emailError })} onBlur={(value) => {
                if (applyParams.email && !valid(applyParams.email, 5)) {
                  setEmailError(language("market35"))
                } else {
                  setEmailError("")
                };
            }}/>
        </FormItem>
          <div className='modalError' style={{ display: emailError ? "block" : "none" }}>{emailError}</div>
        </div>
        <FormItem field="remark" label={language('market36')} labelCol={{ span: eventStation.lang=='zh'?5 : 12 }} >
          <TextArea placeholder={language('subscription90')} max={200} value={applyParams.remark} size={applyParams.remark.length} />
        </FormItem>
      </Form>
    </div>
}
