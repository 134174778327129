import {LOADING} from '../constact'

const loadingIinit = false;
export default function isLoading(preState = loadingIinit, action) {
    const {type, data} = action;
    switch (type) {
        case LOADING:
            return data;
        default:
            return preState;
    }
}