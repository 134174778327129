import React from 'react'
import cs from 'classnames'
import styles from './index.module.less'

export default function Badge(props) {
    const {type, title, style={}, className=""} = props;
  return (
    <div className={cs(styles.badgeBody, {
        [styles.error]:type == 'error',
        [styles.warning]: type == 'warning',
        [styles.success]: type == 'success',
        [styles.gray]: type == 'gray',
        [styles.blue]: type == 'blue'
    }, className)} style={{...style}}>
      {title}
    </div>
  )
}
