import fetch from '@/utils/fetch'
import config from './config'

export default function request(url,set) {
    set = set || {};
    const {type = "POST", loading = true, params, returnAll} = set;
    return fetch({
        url: config[url],
        method: type,
        data: params,
        loading,
        returnAll
    });
}