import { NavLink } from 'react-router-dom'
import cs from 'classnames'

export default function MyNavLink(props) {
    function queryClassName(isActive) {
        if (props.active) {
            return cs({[props.active]:isActive, [props.className]: props.className})
        } else {
            return cs({"active":isActive, [props.className]: props.className})
        }
    }
    return <NavLink {...props} className={({ isActive }) => queryClassName(isActive)}/>
}